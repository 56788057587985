import { Rotation, RotationState } from ".";
import { ConfigurationActions, ConfigurationActionTypes } from "../actions/config";
import { RotationActions, RotationActionTypes } from "../actions/rotation";

const defaultRotation: Rotation = {
    horizontal: 0,
    vertical: 0,
    fov: 90
};

const defaultState: RotationState = {
    current: defaultRotation,
    target: null
};

export const rotationReducer = (
    state: RotationState = defaultState,
    action: RotationActionTypes | ConfigurationActionTypes
): RotationState => {
    switch (action.type) {
        // Update with room config
        case ConfigurationActions.CONFIGURATION_UPDATE:
            if (!action.config.room || !action.config.room.view) {
                return state;
            }
            return {
                ...state,
                current: {
                    horizontal: action.config.room.view.hlookat || state.current.horizontal,
                    vertical: action.config.room.view.vlookat || state.current.vertical,
                    fov: action.config.room.view.fov || state.current.fov
                }
            };

        case RotationActions.ROTATION_UPDATE:
            return {
                ...state,
                current: action.rotation
            };

        case RotationActions.ROTATION_RESET:
            return {
                ...state,
                current: { ...defaultRotation }
            };

        case RotationActions.ROTATE_TO_SET:
            return {
                ...state,
                target: { ...action.rotation, instant: action.instant }
            };

        case RotationActions.ROTATE_TO_CLEAR:
            return {
                ...state,
                target: null
            };

        default:
            return state;
    }
};

