import { FileCategory, FileContentType } from "../actions/file";
import { ApplicationState, FileState } from "../reducers";


export const getFileContents = <T extends FileCategory = any>(state: ApplicationState, file: string): FileContentType<T> => {
    if (state.files[file] == null) {
        return null;
    }
    return (state.files[file] as FileState<T>).contents;
};

export const isFileRequested = (state: ApplicationState, file: string): boolean => {
    if (state.files[file] == null) {
        return false;
    }
    return true;
};

export const getFileError = (state: ApplicationState, file: string): string | null => {
    if (state.files[file] == null) {
        return null;
    }
    return state.files[file].error;
};