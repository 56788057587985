import { FileState, LoadingState } from ".";
import { FileActions, FileActionTypes } from "../actions/file";

export const defaultFileState: FileState = {
    category: "html",
    contents: null,
    error: null,
    name: ""
};

const defaultMap: Record<string, FileState> = {};

export const fileReducer = (
    state: Record<string, FileState> = defaultMap,
    action: FileActionTypes
): Record<string, FileState> => {
    switch (action.type) {
        case FileActions.FILE_REQUEST:
            return {
                ...state,
                [action.file]: {
                    contents: null,
                    error: null,
                    category: action.category,
                    name: action.file
                }
            };

        case FileActions.FILE_FOUND:
            return {
                ...state,
                [action.file]: {
                    category: action.category,
                    contents: action.contents,
                    error: null,
                    name: action.file
                }
            };

        case FileActions.FILE_ERROR:
            return {
                ...state,
                [action.file]: {
                    category: action.category,
                    contents: null,
                    error: action.reason,
                    name: action.file
                }
            };

        default:
            return state;
    }
};