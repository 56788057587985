import { Locale, TranslateableString } from ".";
import { IntlActions, IntlActionTypes } from "../actions/intl";

export const defaultLocale: Locale = {
    code: "en",
    name: "English"
};

export const localeReducer = (state: Locale = defaultLocale, action: IntlActionTypes): Locale => {
    switch (action.type) {
        case IntlActions.LOCALES_UPDATE:
            if (!action.locales.includes(state)) {
                return action.locales[0];
            }
            return state;

        case IntlActions.LOCALE_UPDATE:
            return action.locale;

        default:
            return state;
    }
};

export const localesReducer = (state: Locale[] = [defaultLocale], action: IntlActionTypes): Locale[] => {
    switch (action.type) {
        case IntlActions.LOCALES_UPDATE:
            return action.locales;

        default:
            return state;
    }
};

export const translationsReducer = (
    state: Record<string, TranslateableString> = {},
    action: IntlActionTypes
): Record<string, TranslateableString> => {
    switch (action.type) {
        case IntlActions.TRANSLATION_UPDATE:
            return action.translations;

        default:
            return state;
    }
};