import { useCallback } from "react";
import { Action } from "redux";
import { useDispatch } from "redux-react-hook";

type ActionFactory<Type = any> = (...args: any[]) => Action<Type>;
type CallableAction<T extends ActionFactory> = (...args: Parameters<T>) => void;

export const useAction = <T extends ActionFactory>(factory: T): CallableAction<T> => {
    const dispatch = useDispatch();
    return useCallback((...args: any[]) => dispatch(factory(...args)), []);
};