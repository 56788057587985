import React, { useCallback, useEffect, useRef, useState } from "react";
import SwiperComponent from "react-id-swiper";
import Swiper, { SwiperOptions } from "swiper";
import "swiper/dist/css/swiper.min.css";


export interface SwiperCarouselProps extends SwiperOptions {
    index?: number;
    children?: React.ReactNode;
    swiperRef?: React.MutableRefObject<Swiper | null>;
    updateIndex?(index: number): void;
    shouldSwiperUpdate?: boolean;
}

const SwiperCarousel: React.FunctionComponent<SwiperCarouselProps> = ({
    updateIndex,
    index,
    swiperRef,
    children,
    shouldSwiperUpdate = false,
    ...swiperOptions
}) => {
    const swiper = useRef<Swiper | null>(null);

    const [internalIndex, setInternalIndex] = useState(index || 0);
    const [interacted, setInteracted] = useState(false);

    const assignRefs = useCallback((element: Swiper | null) => {
        swiper.current = element ? element : null;

        if (swiperRef != null) {
            swiperRef.current = element ? element : null;
        }
    }, [swiper, swiperRef]);

    const handleTouchStart = () => {
        setInteracted(true);
    };

    const handleIndexChange = useCallback(() => {
        if (swiper.current == null) {
            return;
        }
        if (updateIndex == null) {
            return;
        }
        setInternalIndex(swiper.current.activeIndex);
        setInteracted(false);
    }, [swiper, updateIndex]);

    useEffect(() => {
        if (swiper.current == null || index == null) {
            return;
        }
        if (swiper.current.activeIndex === index) {
            return;
        }
        swiper.current.slideTo(index);
    }, [index, swiper]);

    useEffect(() => {
        if (interacted && index !== internalIndex && updateIndex) {
            updateIndex(internalIndex);
            setInteracted(false);
        }
    }, [interacted, index, internalIndex, updateIndex]);

    const { on = {}, ...rest } = swiperOptions;
    on.slideChange = handleIndexChange;
    on.sliderMove = handleTouchStart;

    return (
        <SwiperComponent
            shouldSwiperUpdate={shouldSwiperUpdate}
            children={children as any}
            getSwiper={assignRefs}
            {...rest}
            on={on}
        />
    );
};

export default React.memo(SwiperCarousel);