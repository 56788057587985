import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { LoadTarget, startLoading } from "../actions/load";
import { ApplicationState } from "../reducers";
import { canLoad as canLoadSelector, didLoadFinish, getLoadError, getLoadProgress, isLoading } from "../selectors/load";
import App from "./App";
import styles from "./Loader.module.css";
import Progress from "./Progress";

interface StateProps {
    canLoad: boolean;
    didLoad: boolean;
    loading: boolean;
    loadProgress: number;
    loadError: string | null;
}

interface DispatchProps {
    loadContent: () => void;
}

type Props = StateProps & DispatchProps;

const Loader: React.FunctionComponent<Props> = ({ canLoad, loadContent, loading, didLoad, loadError, loadProgress }) => {
    useEffect(() => {
        if (canLoad && !didLoad) {
            loadContent();
        }
    });

    if (!didLoad) {
        return <div className={styles.loader}><Progress message={"Loading..."} value={0} /></div>;
    }

    if (loading) {
        return <div className={styles.loader}><Progress message={"Loading..."} value={loadProgress} /></div>;
    }

    if (loadError) {
        return <div className={styles.loader}><Progress message={loadError} value={0} /></div>;
    }

    return <App />;
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
    canLoad: canLoadSelector(state, LoadTarget.CONTENT),
    loading: isLoading(state, LoadTarget.CONTENT),
    didLoad: didLoadFinish(state, LoadTarget.CONTENT),
    loadError: getLoadError(state, LoadTarget.CONTENT),
    loadProgress: getLoadProgress(state, LoadTarget.CONTENT)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    loadContent: () => dispatch(startLoading(LoadTarget.CONTENT))
});

export default connect(mapStateToProps, mapDispatchToProps)(Loader);


