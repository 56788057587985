import React, { SyntheticEvent } from "react";
import { useLocale } from "../../hooks/useLocale";
import { VideoChapter } from "../../reducers";
import { getTranslatedValue } from "../../services/locale";
import styles from "./VideoContent.module.css";

interface Props {
    chapter: VideoChapter;
}

const VideoContent: React.FunctionComponent<Props> = ({ chapter }) => {
    const [locale] = useLocale();
    const file = getTranslatedValue(chapter.content.source, locale);
    const handleCanPlayThrough = (e: SyntheticEvent<HTMLVideoElement>) => {
        e.currentTarget.play();
    };

    return (
        <div className={styles["video-content"]}>
            {file == null
                ? <div className={styles["content-error"]} />
                : <video autoPlay={false} onCanPlayThrough={handleCanPlayThrough} controls={true} src={file} />}
        </div>

    );
};

export default VideoContent;
