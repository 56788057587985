import { LoadTarget, LoadTargetType } from "../actions/load";
import { ApplicationState, LoadingState } from "../reducers";
import { defaultLoadingState } from "../reducers/load";

export const canLoad = (state: ApplicationState, target: LoadTarget): boolean => {
    if (isLoading(state, target)) {
        return false;
    }

    if (getLoadError(state, target)) {
        return false;
    }

    return true;
};

export const isLoading = (state: ApplicationState, target: LoadTargetType): boolean => {
    return didLoadStart(state, target) && !didLoadFinish(state, target);
};

export const didLoadStart = (state: ApplicationState, target: LoadTargetType): boolean => {
    return getLoadingState(state, target).started;
};

export const didLoadFinish = (state: ApplicationState, target: LoadTargetType): boolean => {
    return getLoadingState(state, target).finished;
};

export const getLoadError = (state: ApplicationState, target: LoadTargetType): string | null => {
    return getLoadingState(state, target).error;
};

export const getLoadProgress = (state: ApplicationState, target: LoadTargetType): number => {
    const { loaded, total } = getLoadingState(state, target).progress;
    return total > 0 ? loaded / total : 0;
};

const getLoadingState = (state: ApplicationState, target: string): LoadingState => {
    if (!state.load || !state.load[target]) {
        return { ...defaultLoadingState };
    }

    return state.load[target];
};