import classNames from "classnames";
import React from "react";
import { Topic } from "../reducers";
import Button from "./Button";
import TranslateableText from "./locale/TranslateableText";
import styles from "./TopicMenuItem.module.css";

interface Props {
    topic: Topic;
    active: boolean;
    activate(topic: Topic): void;
}

const TopicMenuItem = ({ topic, active, activate }: Props) => {
    const handleClick = () => activate(topic);

    return (
        <Button className={classNames(styles["topic-menu-item"], { active })} onTap={handleClick}>
            <img className={styles["topic-menu-item-icon"]} src={topic.icon} />
            <TranslateableText className={styles["topic-menu-item-text"]} data={topic.name} />
        </Button>
    );
};

export default React.memo(TopicMenuItem);
