import { TimelineMax } from "gsap";
import { assign, defaultTo } from "lodash";
import * as React from "react";
import { Transition } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";

interface Props extends TransitionProps {
    selector?: string;
    values?: any;
    stagger?: number;
    duration?: number;

    valuesIn?: any;
    valuesOut?: any;

    durationIn?: any;
    durationOut?: any;
}

export default class Animation extends React.Component<Props> {

    public static defaultProps: Partial<Props> = {
        duration: 0.5,
        stagger: 0.15,
        valuesOut: {
            opacity: 0,
            y: "-=75"
        },
        valuesIn: {
            opacity: 1,
            delay: 0.5,
            y: 0
        }
    };

    private transition?: TimelineMax;
    private entering: boolean = false;

    constructor(props: Props, context: any) {
        super(props, context);

        this.buildProps = this.buildProps.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.handleTransition = this.handleTransition.bind(this);
    }

    private buildProps(): TransitionProps {
        const { selector, stagger, duration, values, durationIn, durationOut, valuesIn, valuesOut, ...props } = this.props;
        const internalProps: TransitionProps = {
            in: true,
            appear: true,
            exit: true,
            enter: true,
            unmountOnExit: true,
            mountOnEnter: true,
            onEnter: this.onEnter,
            onExit: this.onExit,
            addEndListener: this.handleTransition,
            timeout: 1000
        };
        return assign({}, internalProps, props);
    }

    public componentWillUnmount(): void {
        if (this.transition) {
            this.transition.totalProgress(1);
            this.transition.kill();
            delete this.transition;
        }
    }

    private onExit(node: HTMLElement): void {
        this.entering = false;
    }

    private onEnter(node: HTMLElement): void {
        this.entering = true;
    }

    private handleTransition(node: HTMLElement | null, done: () => void): void {
        if (this.transition) {
            this.transition.totalProgress(1);
            this.transition.kill();
        }

        if (node == null) {
            done();
            return;
        }

        let elements: Node[] = [];
        if (this.props.selector) {
            elements = [].slice.call(node.querySelectorAll(this.props.selector));
        } else {
            elements = [node];
        }

        if (elements.length === 0) {
            done();
            return;
        }

        this.transition = new TimelineMax({ onComplete: done });
        if (this.entering) {
            if (elements.length > 1) {
                this.transition.staggerFromTo(elements, this.durationIn(), this.valuesOut(), this.valuesIn(), this.props.stagger);
            } else {
                this.transition.fromTo(elements[0], this.durationIn(), this.valuesOut(), this.valuesIn());
            }
        } else {
            if (elements.length > 1) {
                this.transition.staggerFromTo(elements, this.durationOut() * 0.75, this.valuesIn(), this.valuesOut(), this.props.stagger);
            } else {
                this.transition.fromTo(elements[0], this.durationOut(), this.valuesIn(), this.valuesOut());
            }
        }
    }

    private durationIn(): number {
        return defaultTo(this.props.durationIn, defaultTo(this.props.duration, 0.5));
    }

    private durationOut(): number {
        return defaultTo(this.props.durationOut, defaultTo(this.props.duration, 0.5));
    }

    private valuesIn(): any {
        return Object.assign({}, defaultTo(this.props.valuesIn, defaultTo(this.props.values, {})));
    }

    private valuesOut(): any {
        return Object.assign({}, defaultTo(this.props.valuesOut, defaultTo(this.props.values, {})));
    }

    /**
     * Renders the transition
     */
    public render(): React.ReactNode {
        return <Transition {...this.buildProps()}>{this.props.children}</Transition>;
    }

}