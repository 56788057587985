import React from "react";
import { useStateSelector } from "../../hooks/useStateSelector";
import { Chapter } from "../../reducers";
import { getHotspotConfiguration } from "../../selectors/config";
import { Coordinate, polarToCartesian } from "../../services/math";
import Animation from "../Animation";
import styles from "./ChapterMenu.module.css";
import ChapterMenuItem from "./ChapterMenuItem";

interface Props {
    chapters: Chapter[];
}

const ChapterMenu: React.FunctionComponent<Props> = ({ chapters }) => {
    const config = useStateSelector(getHotspotConfiguration);
    const needed: number = (chapters.length - 1) * config.chapterMenuItemOffset;
    const start: number = 360 - needed / 2;

    const getPosition = (index: number): Coordinate =>
        polarToCartesian({ x: 0, y: 0 }, config.chapterMenuRadius, start + config.chapterMenuItemOffset * index);

    return (
        <div className={styles.menu}>
            {chapters.map((chapter: Chapter, i: number) => {
                return <ChapterMenuItem key={i} chapter={chapter} position={getPosition(i)} />;
            })}
        </div>
    );
};

export default ChapterMenu;
