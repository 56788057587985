import React from "react";
import { useLocale } from "../../hooks/useLocale";
import { TranslateableString } from "../../reducers";
import { getTranslatedValue } from "../../services/locale";

interface Props<T extends HTMLElement = HTMLElement> extends React.HTMLAttributes<T> {
    data: TranslateableString;
    wrapper?: React.ReactType;
}

const TranslateableText = ({ data, wrapper: Wrapper = "span", ...wrapperProps }: Props) => {
    const [locale] = useLocale();
    return <Wrapper {...wrapperProps} dangerouslySetInnerHTML={{ __html: getTranslatedValue(data, locale) }} />;
};


export default React.memo(TranslateableText);