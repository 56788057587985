import { createSelector } from "reselect";
import { ApplicationState, Topic, TopicType } from "../reducers";


export const getTopics = (state: ApplicationState): Topic[] => {
    return state.topics;
};

export const getTopic = (state: ApplicationState): Topic | null => {
    return state.topic;
};

export const getHotspotTopics = createSelector(
    getTopics,
    (topics: Topic[]): Topic[] => topics.filter((topic) => topic.type === TopicType.HOTSPOT)
);

export const getMenuTopics = createSelector(
    getTopics,
    (topics: Topic[]): Topic[] => topics.filter((topic) => topic.type === TopicType.BUTTON)
);