import React from "react";
import { useRotation } from "../../hooks/useRotation";
import { useStateSelector } from "../../hooks/useStateSelector";
import { Rotation } from "../../reducers";
import { getCurrentRotation } from "../../selectors/rotation";
import { Coordinate, describeSlice } from "../../services/math";
import styles from "./LocationIndicator.module.css";


interface Props {
    circleCenter: Coordinate;
    radius: number;
    spread: number;
}

const Position = ({ circleCenter, radius }: Props) => {
    const currentRotation: Rotation = useStateSelector(getCurrentRotation);
    return (
        <path
            d={describeSlice(circleCenter, radius, -radius, currentRotation.horizontal - currentRotation.fov / 2, currentRotation.horizontal + currentRotation.fov / 2)}
            className={styles["inner-circle-position"]}
        />
    );
};


export default Position;


