import React, { } from "react";
import { HtmlChapter, HtmlContent as HtmlContentConfiguration, HtmlFileContent } from "../../reducers";
import TranslateableText from "../locale/TranslateableText";
import LazyHtmlContent from "./LazyHtmlContent";

interface Props {
    chapter: HtmlChapter;
}

const HtmlContent: React.FunctionComponent<Props> = ({ chapter }) => {
    const isFileContent = (content: HtmlContentConfiguration): content is HtmlFileContent => typeof content === "object";
    // @TODO styles shoudl be imported, but we need the variables here
    // CSS variables do not work in IE11. 
    const styles = `
    .small .html-content {
        max-height: calc(62.5vh - 4.5rem);
    }

    .html-content {
        max-height: calc(65vh - 4.5rem);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .html-content h4,
    .html-content h5 {
        font-size: 1.1rem;
    }

    .html-content h2 {
        margin-bottom: 0.25rem;
    }

    .html-content h2 + h4 {
        margin-bottom: 1rem;
    }

    .html-content a {
        color: inherit;
        text-decoration: underline;
        word-break: break-all;
        display: inline-block;
        word-wrap: break-word;
    }

    .html-content a:hover {
        color: #dadada;
    }

    .html-content .row {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    .html-content blockquote {
        margin: 1rem 0;
        padding: 0.75rem;
    }

    .html-content blockquote > div:first-child {
        padding-right: 0;
    }

    .html-content blockquote footer {
        font-weight: 400;
        font-style: italic;
        padding-top: 0.5rem;
    }

    .html-content ul {
        padding-left: 1.25rem;
    }

    .html-content table th,
    .html-content table td {
        padding: 0.5rem 1rem;
    }

    .html-content img.fit {
        width: 100%;
        height: auto;
    }

    .html-content .text-color {
        color: ${chapter.topic.color};
    }

    .html-content .bg-color {
        background-color: ${chapter.topic.color};
    }

    .html-content .bg-color-faded {
        background-color: ${chapter.topic.theme.fadedColor};
    }

    .html-content .comparison-table {
        border-collapse: collapse;
        border-style: hidden;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .html-content .comparison-table th {
        background-color: ${chapter.topic.color};
        border: 1px solid #fff;
        font-weight: bold;
    }

    .html-content .comparison-table th:empty {
        background-color: transparent;
    }

    .html-content .comparison-table tbody td {
        border: 1px solid #fff;
        font-size: 0.925em;
    }

    .html-content .comparison-table tbody td:first-child {
        text-align: right;
        font-weight: bold;
        font-size: 1em;
        background-color: ${chapter.topic.theme.fadedColor};
    }

    .html-content .step-table {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-collapse: collapse;
        border-style: hidden;
        border-left: 1px solid ${chapter.topic.color};
        border-right: 1px solid ${chapter.topic.color};
        border-bottom: 1px solid ${chapter.topic.color};
    }

    .html-content .step-table td,
    .html-content .step-table th {
        border: 1px solid ${chapter.topic.color};
    }

    .html-content .step-table th {
        background-color: ${chapter.topic.theme.fadedColor};
    }

    .html-content .step-table .step-label {
        background-color: ${chapter.topic.color};
        text-align: center;
        font-weight: bold;
    }

    .html-content .step-table tbody td {
        font-size: 0.925em;
    }`;

    return (
        <div className={"html-content"}>
            <style dangerouslySetInnerHTML={{ __html: styles }} />
            {isFileContent(chapter.content)
                ? <LazyHtmlContent file={chapter.content.source} />
                : <TranslateableText data={chapter.content} />
            }
        </div>
    );
};

export default HtmlContent;
