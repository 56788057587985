import classNames from "classnames";
import React, { useEffect, useState, useCallback } from "react";
import { closeChapter } from "../../actions/chapter";
import { useAction } from "../../hooks/useAction";
import { Chapter, ChapterType, TopicType } from "../../reducers";
import logger from "../../services/logger";
import styles from "./ContentBox.module.css";
import ContentBoxCloseButton from "./ContentBoxCloseButton";
import GameContent from "./GameContent";
import HtmlContent from "./HtmlContent";
import SlideshowContent from "./SlideshowContent";
import VideoContent from "./VideoContent";

interface Props {
    chapter: Chapter | null;
}


const ContentBox: React.FunctionComponent<Props> = ({ chapter }) => {
    const [activeChapter, setActiveChapter] = useState(chapter);
    const closeAction = useAction(closeChapter);
    const close = useCallback(
        () => closeAction(activeChapter && activeChapter.topic.type === TopicType.HOTSPOT ? true : false),
        [activeChapter]
    );

    useEffect(() => {
        if (chapter != null) {
            setActiveChapter(chapter);
        }
    }, [chapter]);

    if (activeChapter == null) {
        return null;
    }

    const renderContent = () => {
        switch (activeChapter.type) {
            case ChapterType.HTML:
                return <HtmlContent chapter={activeChapter} />;

            case ChapterType.VIDEO:
                return <VideoContent chapter={activeChapter} />;

            case ChapterType.GAME:
                return <GameContent chapter={activeChapter} />;

            case ChapterType.SLIDESHOW:
                return <SlideshowContent chapter={activeChapter} />;

            default:
                logger.warn(`Invalid chapter type`);
                return null;
        }
    };

    const style = ({
        backgroundColor: activeChapter.topic.theme.contentBackgroundColor
    });

    const borderStyle = ({
        borderColor: activeChapter.topic.theme.contentBorderColor
    });

    const closeButtonStyle = ({
        iconBorderColor: activeChapter.topic.theme.closeButtonBorderColor,
        backgroundColor: activeChapter.topic.theme.closeButtonBackgroundColor
    });

    return (
        <div className={classNames(styles["content-box"], activeChapter.boxSize, activeChapter.type)} style={style}>
            <div className={classNames(styles["content-box-border"], "left")} style={borderStyle} />
            <div className={classNames(styles["content-box-border"], "right")} style={borderStyle} />
            {renderContent()}
            <ContentBoxCloseButton closeChapter={close} {...closeButtonStyle} />
        </div>
    );
};

export default ContentBox;
