import React from "react";
import { CSSTransition } from "react-transition-group";
import { leaveIntro } from "../../actions/intro";
import { useAction } from "../../hooks/useAction";
import { useStateSelector } from "../../hooks/useStateSelector";
import { Video } from "../../krpano/Image";
import Krpano from "../../krpano/Krpano";
import View from "../../krpano/View";
import { getIntroConfiguration } from "../../selectors/config";
import { isIntroActive } from "../../selectors/intro";
import { buildPublicPath } from "../../services/path";
import styles from "../App.module.css";
import RotationListener from "../pano/RotationListener";

const Intro = () => {
    const config = useStateSelector(getIntroConfiguration);
    const playing = useStateSelector(isIntroActive);
    const deactivateIntro = useAction(leaveIntro);

    return (
        <CSSTransition unmountOnExit={true} mountOnEnter={true} timeout={1200} classNames="pano-fade" in={playing}>
            <Krpano wmode="transparent" wrapper={{ className: styles["krpano-wrapper"] }}>
                <RotationListener disabled={!playing} />
                <View {...config.view} />
                <Video video={{ onvideocomplete: deactivateIntro }} url={buildPublicPath(config.video)} />
            </Krpano>
        </CSSTransition>
    );
};

export default React.memo(Intro);
