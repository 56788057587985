import React from "react";
import ChapterContainer from "./chapter/ChapterContainer";
import IdleHandler from "./IdleHandler";
import Intro from "./intro/Intro";
import Room from "./pano/Room";
import StaticUI from "./StaticUI";

const App = () => {
    return (
        <div className="content">
            <IdleHandler />
            <Intro />
            <Room />
            <StaticUI />
            <ChapterContainer />
        </div>
    );
};

export default App;
