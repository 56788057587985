import { AnyAction } from "redux";
import { call, put, select, take, takeEvery } from "redux-saga/effects";
import { updateConfiguration } from "../actions/config";
import { FileActions, FileCategory, fileError, FileRequestAction, foundFile } from "../actions/file";
import { updateLocales, updateTranslations } from "../actions/intl";
import { completeLoading, failedLoading, LoadActions, LoadStartAction, LoadTarget, progressLoading, startLoading } from "../actions/load";
import { updateTopics } from "../actions/topic";
import { isFileRequested } from "../selectors/files";
import { didLoadFinish } from "../selectors/load";
import { buildPublicPath } from "../services/path";



const fileToActionMap: Record<string, (data: any) => AnyAction> = {
    "content/locales.json": updateLocales,
    "content/topics.json": updateTopics,
    "content/translations.json": updateTranslations
};

function* loadJsonFile(file: string) {
    const response: Response = yield call(fetch, buildPublicPath(file));
    return yield call([response, "json"]);
}

function* loadHtmlFile(file: string) {
    const response: Response = yield call(fetch, buildPublicPath(file));
    return yield call([response, "text"]);
}

function* loadConfig() {
    const configLoaded: boolean = yield select(didLoadFinish, LoadTarget.CONFIG);
    if (!configLoaded) {
        try {
            yield put(updateConfiguration(yield loadJsonFile("content/config.json")));
        } catch (e) {
            yield put(failedLoading(LoadTarget.CONFIG, e.toString()));
        }
    }

    yield put(completeLoading(LoadTarget.CONFIG));
}

function* loadContent() {
    const files: string[] = Object.keys(fileToActionMap);
    const total: number = files.length;

    let loaded = 0;
    yield put(progressLoading(LoadTarget.CONTENT, loaded, total));

    for (const file of files) {
        const handler = fileToActionMap[file];
        try {
            yield put(handler(yield loadJsonFile(file)));
        } catch (e) {
            return yield put(failedLoading(LoadTarget.CONTENT, `Error occured while loading file ${file}: ${e.toString()}`));
        }

        yield put(progressLoading(LoadTarget.CONTENT, ++loaded, 2));
    }

    yield put(completeLoading(LoadTarget.CONTENT));
}

function* handleLoadStart(action: LoadStartAction) {
    switch (action.target) {
        case LoadTarget.CONTENT:
            yield put(startLoading(LoadTarget.CONFIG));
            // Wait until config is loaded.
            yield take(LoadActions.LOAD_COMPLETE);
            return yield loadContent();

        case LoadTarget.CONFIG:
            return yield loadConfig();
    }
}

function* loadFile<T extends FileCategory>(file: string, category: T) {
    switch (category) {
        case "html":
            return yield loadHtmlFile(file);

        case "json":
            return yield loadJsonFile(file);
    }

    return null;
}

function* handleFileRequest(action: FileRequestAction) {
    yield put(startLoading(action.file));

    try {
        yield put(foundFile(action.file, action.category, yield loadFile(action.file, action.category)));
    } catch (e) {
        const message = `Error occured while loading file ${action.file}: ${e.toString()}`;
        yield put(fileError(action.file, action.category, message));
        return yield put(failedLoading(action.file, message));
    }

    yield put(completeLoading(action.file));
}

export default [
    takeEvery(LoadActions.LOAD_START, handleLoadStart),
    takeEvery(FileActions.FILE_REQUEST, handleFileRequest)
];