import { useCallback } from "react";
import { useMappedState } from "redux-react-hook";
import { ApplicationState } from "../reducers";

type Selector<T> = (state: ApplicationState) => T;

export const useMappedStateSelectors = <T extends object>(selectors: Record<keyof T, Selector<T[keyof T]>>): T => {
    const memoizedSelectors = useCallback(
        (state: ApplicationState) => {
            return Object.keys(selectors).reduce(
                (result: T, key: string) => {
                    result[key as keyof T] = selectors[key as keyof T](state);
                    return result;
                },
                {} as T
            );
        },
        []
    );

    return useMappedState<T>(memoizedSelectors);
};