import classNames from "classnames";
import React, { useMemo } from "react";
import { Locale } from "../../reducers";
import { centroidOfCircularSector, describeSlice, polarToCartesian } from "../../services/math";
import Button from "../Button";
import TranslateableText from "../locale/TranslateableText";
import styles from "./LanguageSlice.module.css";

interface Props {
    locale: Locale;
    active: boolean;
    activate(locale: Locale): void;
    circleRadius: number;
    slicePadding: number;
    rotation: number;
    angle: number;
}

const LanguageSlice = ({ active, locale, circleRadius, slicePadding, rotation, angle, activate }: Props) => {
    const handleClick = () => activate(locale);

    const start = rotation;
    const end = rotation + angle;
    const center = {
        x: circleRadius,
        y: circleRadius
    };

    const path = useMemo(
        () => describeSlice(center, circleRadius, -circleRadius, start, end, slicePadding),
        [center, circleRadius, start, end, slicePadding]
    );

    const textCenter = useMemo(
        () => polarToCartesian(center, centroidOfCircularSector(circleRadius - slicePadding, angle) + slicePadding, (start + end) / 2),
        [center, circleRadius, slicePadding, start, end]
    );

    return (
        <Button component={"g"} className={classNames(styles["language-slice"], { active })} onTap={handleClick}>
            <path d={path} />
            <text x={textCenter.x} y={textCenter.y} alignmentBaseline="middle" textAnchor="middle">
                <TranslateableText wrapper={"tspan"} data={locale.name} />
            </text>
        </Button>
    );
};


export default LanguageSlice;
