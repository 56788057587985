import React, { HTMLProps } from "react";
import Button from "../Button";
import Translation from "../locale/Translation";
import styles from "./ContentBoxCloseButton.module.css";


interface Props {
    closeChapter(): void;
    iconBorderColor: string;
    backgroundColor: string;
}

const ContentBox: React.FunctionComponent<Props> = ({ iconBorderColor, backgroundColor, closeChapter }) => {
    return (
        <Button onTap={closeChapter} className={styles.btn}>
            <svg className={styles.icon} viewBox="0 0 36 36" style={{ backgroundColor, borderColor: iconBorderColor }}>
                <path d="M 6,6 L 30,30 M 30,6 L 6,30" />
            </svg>
            <span className={styles.text}><Translation name="close" /></span>
        </Button>
    );
};

export default React.memo(ContentBox);
