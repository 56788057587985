import React from "react";
import { useStateSelector } from "../../hooks/useStateSelector";
import Autorotate from "../../krpano/Autorotate";
import { getIdleConfiguration } from "../../selectors/config";
import { isIdle } from "../../selectors/idle";

const AutorotateHandler = () => {
    const idle = useStateSelector(isIdle);
    const config = useStateSelector(getIdleConfiguration);
    return <Autorotate {...config.autorotate} enabled={idle} waittime={0} />;
};

export default AutorotateHandler;
