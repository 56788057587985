import { enterIdle, leaveIdle } from "../actions/idle";
import { isIdle } from "../selectors/idle";
import { useAction } from "./useAction";
import { useStateSelector } from "./useStateSelector";

interface IdleInfo {
    isIdle: boolean;
    enterIdle: () => void;
    leaveIdle: () => void;
}

export const useIdle = (): IdleInfo => {
    return {
        isIdle: useStateSelector(isIdle),
        enterIdle: useAction(enterIdle),
        leaveIdle: useAction(leaveIdle)
    };
};