import React, { useMemo } from "react";
import { activateTopic } from "../../actions/topic";
import { HotspotConfiguration, IndicatorConfiguration } from "../../data/Configuration";
import { useAction } from "../../hooks/useAction";
import { useMappedStateSelectors } from "../../hooks/useMappedStateSelectors";
import { Topic } from "../../reducers";
import { getHotspotConfiguration, getIndicatorConfiguration } from "../../selectors/config";
import { getHotspotTopics, getTopic } from "../../selectors/topic";
import { findBiggestGap } from "../../services/math";
import InnerRing from "./InnerRing";
import styles from "./LocationIndicator.module.css";
import Position from "./Position";
import Slice from "./Slice";

interface State {
    hotspotConfiguration: HotspotConfiguration;
    configuration: IndicatorConfiguration;
    topics: Topic[];
    activeTopic: Topic | null;
}

const LocationIndicator = () => {
    const { topics, activeTopic, configuration, hotspotConfiguration } = useMappedStateSelectors<State>({
        topics: getHotspotTopics,
        activeTopic: getTopic,
        configuration: getIndicatorConfiguration,
        hotspotConfiguration: getHotspotConfiguration
    });

    if (topics.length === 0) {
        return null;
    }

    const center = {
        x: configuration.backgroundCircleRadius,
        y: configuration.backgroundCircleRadius
    };
    const radius = configuration.backgroundCircleRadius;
    const size = radius * 2;

    const sliceSpread = {
        w: hotspotConfiguration.visibleRange,
        h: configuration.outerRingWidth
    };

    const activate = useAction(activateTopic);
    const handleActivate = (topic: Topic) => activate(topic, true);

    const biggestGap = useMemo(() => {
        const itemNumbers: number[] = topics.map(({ sphericalCoordinates }) => sphericalCoordinates.horizontalAngle);
        return findBiggestGap(itemNumbers);
    }, [topics]);

    return (
        <svg className={styles.indicator} viewBox={`0 0 ${size} ${size}`} preserveAspectRatio="xMidYMid meet">

            <InnerRing
                gap={biggestGap}
                radius={configuration.innerRingRadius}
                sliceSpread={sliceSpread}
                spread={configuration.innerRingWidth}
                circleCenter={center}
            />
            <Position radius={configuration.innerRingRadius} spread={configuration.innerRingWidth} circleCenter={center} />
            {topics.map((topic: Topic, i: number) =>
                <Slice
                    key={i}
                    activate={handleActivate}
                    radius={configuration.outerRingRadius}
                    topic={topic}
                    active={topic === activeTopic}
                    sliceCenterPosition={topic.sphericalCoordinates.horizontalAngle}
                    sliceSpread={sliceSpread}
                    circleCenter={center}
                />
            )}
        </svg>
    );
};

export default LocationIndicator;


