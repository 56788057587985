
import React, { useMemo } from "react";
import { Topic } from "../../reducers";
import { Coordinate, describeSlice, polarToCartesian, Size } from "../../services/math";
import Button from "../Button";
import styles from "./LocationIndicator.module.css";


interface Props {
    topic: Topic;
    circleCenter: Coordinate;
    radius: number;
    sliceCenterPosition: number;
    sliceSpread: Size;
    active: boolean;
    activate(topic: Topic): void;
}


const Slice = ({ active, activate, topic, circleCenter, sliceSpread, radius, sliceCenterPosition }: Props) => {
    const circlePoint = polarToCartesian(circleCenter, radius / 5 * 3, sliceCenterPosition);
    const start = sliceCenterPosition - sliceSpread.w;
    const end = sliceCenterPosition + sliceSpread.w;

    const path = useMemo(
        () => describeSlice(circleCenter, radius, -sliceSpread.h, start, end),
        [circleCenter, radius, start, end, sliceSpread]
    );

    const backgroundPath = useMemo(
        () => describeSlice(circleCenter, radius + 10, -radius - 10 , start, end),
        [circleCenter, radius, start, end]
    );

    const handleClick = () => activate(topic);

    return (
        <Button component={"g"} onTap={handleClick}>
            <path
                className={styles["outer-circle-slice"]}
                d={path}
                fill={active ? topic.color : "white"}
            />
            <path className={styles["background-slice"]} d={backgroundPath} fill="red" opacity={0} />
            <circle cx={circlePoint.x} cy={circlePoint.y} r={5} fill={topic.color} />
        </Button>
    );
};

export default React.memo(Slice);


