import { combineReducers, Reducer } from "redux";
import { FileCategory, FileContentType } from "../actions/file";
import { LoadTargetType } from "../actions/load";
import { Configuration } from "../data/Configuration";
import { chapterReducer } from "./chapter";
import { configReducer } from "./config";
import { fileReducer } from "./files";
import { idleReducer } from "./idle";
import { localeReducer, localesReducer, translationsReducer } from "./intl";
import { introReducer } from "./intro";
import { loadReducer } from "./load";
import { rotationReducer } from "./rotation";
import { topicReducer, topicsReducer } from "./topic";

/**
 * Describes some rotatation properties of the pano
 */
export interface Rotation {
    /**
     * Horizontal angle in degrees
     */
    horizontal: number;

    /**
     * Vertical angle in degrees
     */
    vertical: number;

    /**
     * The field of view
     */
    fov: number;
}

/**
 * A string which can have a value per locale
 */
export type TranslateableString = Translateable<string>;

/**
 * A value which can have a value per locale
 */
export type Translateable<T> = Array<Translation<T>> | T;

/**
 * A list of translateable values
 */
export interface TranslateableList<T = string> {
    [key: string]: Translateable<T>;
}

/**
 * A translation of a value.
 */
export interface Translation<T> {
    /**
     * Locale code
     */
    locale: string;

    /**
     * Value in the given locale
     */
    value: T;
}

/**
 * Type of a topic
 */
export enum TopicType {
    HOTSPOT = "hotspot",
    BUTTON = "button"
}

/**
 * Configuration of a topic
 */
export interface Topic {
    /**
     * The type of the copic
     * If set to "hotspot" a hotspot will be created on the pano
     * If set to "button" a button will be added to the bottom left menu
     * 
     * @default hotspot
     * @nullable
     */
    type: TopicType;

    /**
     * Path to the icon of the topic
     */
    icon: string;

    /**
     * Color theme of the topic
     */
    color: string;

    /**
     * Color theme of the topic
     * 
     * @nullable
     */
    theme: TopicColorTheme;

    /**
     * Name of the topic
     */
    name: TranslateableString;

    /**
     * The spherical coordinates of the hotspot in the panorama
     * 
     * @nullable
     */
    sphericalCoordinates: SphericalCoordinates;

    /**
     * Chapters of the topic
     */
    chapters: Chapter[];

    /**
     * Path pattern for the panorama overlay
     */
    panoramaOverlay?: string;
}

/**
 * Color theme of a topic
 */
export interface TopicColorTheme {
    fadedColor: string;
    iconBackgroundColor: string;
    contentBorderColor: string;
    contentBackgroundColor: string;
    closeButtonBorderColor: string;
    closeButtonBackgroundColor: string;
    overlayColor: string;
}

/**
 * The type of a chapter
 */
export enum ChapterType {
    /**
     * Renders a video with controls
     */
    VIDEO = "video",

    /**
     * Renders a full html document as iframe
     */
    GAME = "game",

    /**
     * Renders a partial html file or contents
     */
    HTML = "html",

    /**
     * Renders an image slideshow
     */
    SLIDESHOW = "slideshow"
}

/**
 * Configuration of a chapter
 */
export interface BaseChapter<Type extends ChapterType, Content> {

    /**
     * The type of the chapter
     */
    type: Type;

    /**
     * Configure the box size of the chapter.
     * If not set there is a internal config for the type
     */
    boxSize?: "small" | "medium" | "big";

    /**
     * name of the icon
     * If not set there is a internal config for the type
     */
    buttonIcon?: string;

    /**
     * Text on the button
     */
    buttonText?: TranslateableString;

    /**
     * Percentage button text size
     */
    buttonTextSize?: number;

    /**
     * A small note next to the icon of the button
     */
    buttonNote?: TranslateableString;

    /**
     * Content of the chapter
     */
    content: Content;

    /**
     * Will be set internally
     * @ignore
     */
    topic: Topic;
}

/**
 * Chapter for videos
 * 
 * @title Video chapter
 */
export type VideoChapter = BaseChapter<ChapterType.VIDEO, VideoContent>;

/**
 * Video content configuration
 */
export interface VideoContent {
    /**
     * Path to the video source file
     */
    source: TranslateableString;
}

/**
 * Chapter for games
 * 
 * @title Gamer chapter
 */
export type GameChapter = BaseChapter<ChapterType.GAME, GameContent>;

/**
 * Game content configuration
 */
export interface GameContent {
    /**
     * Path to the html file of the game
     */
    source: TranslateableString;
}

/**
 * Chapter for slideshows
 * 
 * @title Slideshow chapter
 */
export type SlideshowChapter = BaseChapter<ChapterType.SLIDESHOW, SlideshowContent>;

/**
 * Slideshow content configuration
 */
export interface SlideshowContent {
    /**
     * If true left and right slide buttons will be displayed
     */
    controls?: boolean;

    /**
     * If true an indicator with current and total will be displayed on the bottom of the slider
     */
    indicator?: boolean;

    /**
     * Source configuration of the slideshow
     */
    source: Translateable<SlideshowSource>;
}

/**
 * Source configuration of the slideshow
 */
export interface SlideshowSource {
    /**
     * Start counting, defaults to 0
     */
    start?: number;

    /**
     * Number of total slides
     */
    count: number;

    /**
     * 
     * The pattern for the slide paths
     * Named sprintf arguments %(locale)s and %(index)d can be used
     * To get zero padding use: %(index)05d
     * 
     * e.g. content/img/slidehow_%(locale)s/slide_%(index)d.png
     */
    pathPattern: string;
}

/**
 * Chapter with html contents
 * 
 * @title Html chapter
 */
export type HtmlChapter = BaseChapter<ChapterType.HTML, HtmlContent>;

/**
 * Html content configuration
 */
export type HtmlContent = TranslateableString | HtmlFileContent;

export interface HtmlFileContent {
    /**
     * Path to a partial html file
     */
    source: TranslateableString;
}

/**
 * Chapter of a topic
 */
export type Chapter = GameChapter | VideoChapter | HtmlChapter | SlideshowChapter;

/**
 * Spherical coordinates
 */
export interface SphericalCoordinates {
    /**
     * Horizonal angle in degrees
     * 
     * @minimum 0
     * @maximum 360
     */
    horizontalAngle: number;

    /**
     * Vertical angle in degrees
     * 
     * @minimum 0
     * @maximum 360
     */
    verticalAngle: number;
}

/**
 * Describes a locale
 */
export interface Locale {
    /**
     * Unique locale code
     */
    code: string;

    /**
     * Name of the locale
     */
    name: TranslateableString;
}

/**
 * Describes loading of a resource
 */
export interface LoadingState {
    /**
     * Has the loading started?
     */
    started: boolean;

    /**
     * Did the loading finish
     */
    finished: boolean;

    /**
     * Loading progress
     */
    progress: {
        loaded: number;
        total: number
    };

    /**
     * Loading error
     */
    error: string | null;
}


export interface RotationState {
    current: Rotation;
    target: (Rotation & { instant: boolean }) | null;
}

export interface FileState<T extends FileCategory = any> {
    name: string;
    category: T;
    contents: FileContentType<T>;
    error: string | null;
}

export interface ApplicationState {
    load: Record<LoadTargetType, LoadingState>;

    /**
     * App configuration
     */
    config: Configuration;

    /**
     * Active locale
     */
    locale: Locale;

    /**
     * Available locales
     */
    locales: Locale[];

    /**
     * Active topic
     */
    topic: Topic | null;

    /**
     * Available topics
     */
    topics: Topic[];

    /**
     * Active chapter
     */
    chapter: Chapter | null;

    /**
     * Is in idle mode?
     */
    idle: boolean;

    /**
     * Is showing the intro?
     */
    intro: boolean;

    /**
     * Current rotation of the pano
     */
    rotation: RotationState;

    /**
     * Loaded files
     */
    files: Record<string, FileState>;

    /**
     * Translations
     */
    translations: Record<string, TranslateableString>;
}

export type LocalesConfigurationFile = Locale[];
export type TranslationsConfigurationFile = TranslateableList<string>;
export type TopicsConfigurationFile = Topic[];
export type ConfigurationFile = Configuration;

const createReducer = (reducers?: Record<string, Reducer<any, any>>) => {
    return combineReducers<ApplicationState>({
        config: configReducer,
        load: loadReducer,
        locale: localeReducer,
        locales: localesReducer,
        chapter: chapterReducer,
        files: fileReducer,
        topic: topicReducer,
        topics: topicsReducer,
        idle: idleReducer,
        rotation: rotationReducer,
        intro: introReducer,
        translations: translationsReducer,
        ...reducers
    });
};

export default createReducer;