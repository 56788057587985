import React from "react";
import { openChapter } from "../actions/chapter";
import { useAction } from "../hooks/useAction";
import { useMappedStateSelectors } from "../hooks/useMappedStateSelectors";
import { Topic } from "../reducers";
import { getMenuTopics, getTopic } from "../selectors/topic";
import logger from "../services/logger";
import styles from "./TopicMenu.module.css";
import TopicMenuItem from "./TopicMenuItem";

interface State {
    topics: Topic[];
    activeTopic: Topic | null;
}

const TopicMenu = () => {
    const { topics, activeTopic } = useMappedStateSelectors<State>({
        topics: getMenuTopics,
        activeTopic: getTopic
    });

    const activateChapter = useAction(openChapter);

    const handleActivate = (topic: Topic) => {
        if (topic.chapters.length === 0) {
            logger.warn(`No chapter configured for topic`);
            return;
        }
        activateChapter(topic.chapters[0]);
    };

    return (
        <div className={styles["topic-menu"]}>
            {topics.map((topic, i: number) =>
                <TopicMenuItem activate={handleActivate} key={i} active={topic === activeTopic} topic={topic} />)}
        </div>
    );
};

export default React.memo(TopicMenu);
