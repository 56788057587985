
export const buildPublicPath = (file: string): string => {
    const path: string = file.replace(/^\/+/g, "");
    if (process.env.PUBLIC_URL.length > 0
        && process.env.PUBLIC_URL !== "/"
    ) {
        return process.env.PUBLIC_URL + "/" + path;
    }
    return path;
};

export const getBasename = (file: string): string => {
    const lastPart = file.split(/[\\/]/).pop();
    if (!lastPart) {
        return file;
    }
    if (lastPart.lastIndexOf(".") !== -1) {
        return lastPart.substring(0, lastPart.lastIndexOf("."));
    }

    return lastPart;
};