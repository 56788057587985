import { Action } from "redux";
import { Rotation } from "../reducers";

export enum RotationActions {
    ROTATE_TO_SET = "ROTATE_TO_SET",
    ROTATE_TO_CLEAR = "ROTATE_TO_CLEAR",
    ROTATION_UPDATE = "ROTATION_UPDATE",
    ROTATION_RESET = "ROTATION_RESET"
}

export interface RotateToSetAction extends Action<RotationActions.ROTATE_TO_SET> {
    rotation: Rotation;
    instant: boolean;
}

export interface RotateToClearAction extends Action<RotationActions.ROTATE_TO_CLEAR> { }

export interface RotationUpdateAction extends Action<RotationActions.ROTATION_UPDATE> {
    rotation: Rotation;
}

export interface RotationResetAction extends Action<RotationActions.ROTATION_RESET> { }

export const updateRotation = (rotation: Rotation): RotationUpdateAction => ({
    type: RotationActions.ROTATION_UPDATE,
    rotation
});

export const resetRotation = (): RotationResetAction => ({
    type: RotationActions.ROTATION_RESET
});

export const setRotateTo = (rotation: Rotation, instant: boolean = false): RotateToSetAction => ({
    type: RotationActions.ROTATE_TO_SET,
    rotation,
    instant
});

export const clearRotateTo = (): RotateToClearAction => ({
    type: RotationActions.ROTATE_TO_CLEAR
});

export type RotationActionTypes = RotateToClearAction | RotateToSetAction | RotationUpdateAction | RotationResetAction;
