import React, { CSSProperties } from "react";
import { CSSTransition } from "react-transition-group";
import { closeChapter } from "../../actions/chapter";
import { useAction } from "../../hooks/useAction";
import { useStateSelector } from "../../hooks/useStateSelector";
import { TopicType } from "../../reducers";
import { getChapter } from "../../selectors/chapter";
import Button from "../Button";
import styles from "./Chapter.module.css";
import ContentBox from "./ContentBox";

const ChapterContainer = () => {
    const chapter = useStateSelector(getChapter);
    const close = useAction(closeChapter);
    const handleClose = () => close(chapter && chapter.topic.type === TopicType.HOTSPOT ? true : false);

    const containerStyle: CSSProperties = {};
    if (chapter != null) {
        containerStyle.backgroundColor = chapter.topic.theme.overlayColor;
    }

    return (
        <CSSTransition classNames={"fade"} unmountOnExit={true} mountOnEnter={true} timeout={600} in={chapter != null}>
            <div className={styles["chapter-container"]} style={containerStyle}>
                <Button firstInteractionDelay={750} component="div" className={styles["chapter-container-close-layer"]} onTap={handleClose} />
                <ContentBox chapter={chapter} />
            </div>
        </CSSTransition>
    );
};

export default ChapterContainer;
