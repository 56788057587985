import * as Logger from "js-logger";

Logger.useDefaults();
const logger = Logger.get("compliance-app");

logger.setLevel(process.env.NODE_ENV === "production"
    ? Logger.ERROR
    : Logger.DEBUG
);

export default logger;