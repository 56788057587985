import React from "react";
import { CSSTransition } from "react-transition-group";
import { useStateSelector } from "../../hooks/useStateSelector";
import { CubeImage } from "../../krpano/Image";
import Krpano from "../../krpano/Krpano";
import View from "../../krpano/View";
import { getRoomConfiguration } from "../../selectors/config";
import { isIntroActive } from "../../selectors/intro";
import styles from "../App.module.css";
import AutorotateHandler from "./AutorotateHandler";
import Hotspots from "./Hotspots";
import Overlay from "./Overlay";
import RotationHandler from "./RotationHandler";
import RotationListener from "./RotationListener";


const Room = () => {
    const config = useStateSelector(getRoomConfiguration);
    const intro = useStateSelector(isIntroActive);

    return (
        <CSSTransition mountOnEnter={true} unmountOnExit={true} classNames="pano-fade" in={!intro} timeout={1200}>
            <Krpano wmode="transparent" wrapper={{ className: styles["krpano-wrapper"] }}>
                <RotationListener disabled={intro} />
                <RotationHandler />
                <View {...config.view} />
                <AutorotateHandler />
                <CubeImage preview={{ url: config.previewImage }} url={config.image} />

                <Overlay />
                <Hotspots />
            </Krpano>
        </CSSTransition>
    );
};

export default React.memo(Room);
