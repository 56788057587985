import { applyMiddleware, createStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import createReducer from "../reducers";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [
    sagaMiddleware
];

if (process.env.NODE_ENV !== "production") {
    const loggerMiddleware: Middleware = createLogger({
        duration: true,
        collapsed: true,
        diff: false
    });
    middlewares.push(loggerMiddleware);
}

/**
 * Redux store
 * @see https://redux.js.org/api-reference/store
 */
export const store = createStore(
    createReducer(),
    applyMiddleware(...middlewares)
);

sagaMiddleware.run(rootSaga);