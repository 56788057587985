import React, { useContext, useEffect } from "react";
import { Omit } from "react-redux";
import {
    Krpano,
    KrpanoCubeStripImageOptions,
    KrpanoCylinderImageOptions,
    KrpanoFisheyeImageOptions,
    KrpanoImageLevelOptions,
    KrpanoImageOptions,
    KrpanoImageType,
    KrpanoPreviewElement,
    KrpanoSphereImageOptions,
    KrpanoVideoPluginOptions
} from "../types/krpano";
import { KrpanoContext } from "./Krpano";
import { VideoPlugin } from "./Plugin";

interface State {
    krpano: Krpano | null;
}

interface ImageOptions<T extends object = {}> extends KrpanoImageOptions {
    url: string;
    imageOptions?: T;
    preview?: KrpanoPreviewElement;
    levels?: Array<Partial<KrpanoImageLevelOptions>>;
}

interface Props extends ImageOptions {
    type: KrpanoImageType;
}

const Image = ({ levels, url, type, preview, imageOptions, ...rest }: Props) => {
    const context = useContext(KrpanoContext);

    /**
     * Sets multiple attributes of a given node
     * 
     * @param node 
     * @param attributes 
     */
    const setMultipleAttributes = (node: Element, attributes: Record<string, any>): void => {
        Object.keys(attributes).forEach((key) => {
            if (attributes[key] == null) {
                return;
            }
            node.setAttribute(key, attributes[key].toString());
        });
    };

    /**
     * Creates the xml preview node
     * 
     * @param doc 
     */
    const createPreviewNode = (doc: Document): Element | null => {
        if (preview == null) {
            return null;
        }

        const node = doc.createElement("preview");
        setMultipleAttributes(node, preview);

        return node;
    };

    /**
     * Creates the image node
     * 
     * @param doc 
     */
    const createImageNode = (doc: Document): Element => {
        const image = doc.createElement("image");
        setMultipleAttributes(image, rest);

        if (levels != null) {
            for (const level of levels) {
                const node = doc.createElement("level");
                setMultipleAttributes(node, level);
                image.appendChild(node);
            }
        }

        const inner = doc.createElement(type);
        inner.setAttribute("url", url);
        if (imageOptions != null) {
            setMultipleAttributes(inner, imageOptions);
        }

        image.appendChild(inner);
        return image;
    };

    const createXml = () => {
        const doc = document.implementation.createDocument("", "", null);
        const root = document.createElement("krpano");

        root.appendChild(createImageNode(doc));

        const previewNode: Element | null = createPreviewNode(doc);
        if (previewNode != null) {
            root.appendChild(previewNode);
        }

        doc.appendChild(root);
        return root;
    }

    /**
     * Updates the pano image
     */
    const updateImage = () => {
        if (context == null) {
            return;
        }

        setTimeout(() => {
            context.actions.loadxml(new XMLSerializer().serializeToString(createXml()), {}, "IMAGEONLY|KEEPALL");
        });
    };

    useEffect(updateImage);
    return null;
};

export const KrpanoImage = React.memo(Image);

type VideoOptions = ImageOptions & {
    video?: Omit<KrpanoVideoPluginOptions, "name" | "videourl" | "posterurl">
};

export const Video: React.FunctionComponent<VideoOptions> = ({ url, preview, video = {}, ...rest }) => {
    return (
        <React.Fragment>
            <VideoPlugin videourl={url} posterurl={preview ? preview.url : undefined} {...video} />
            <KrpanoImage type="sphere" url={"plugin:video"}  {...rest} />
        </React.Fragment>
    );
};

export const SphereImage = (props: ImageOptions<KrpanoSphereImageOptions>) => {
    return <KrpanoImage type="sphere" {...props} />;
};

export const CubeImage = (props: ImageOptions) => {
    return <KrpanoImage type="cube" {...props} />;
};

export const CubeStripImage = (props: ImageOptions<KrpanoCubeStripImageOptions>) => {
    return <KrpanoImage type="cube" {...props} />;
};

export const CylinderImage = (props: ImageOptions<KrpanoCylinderImageOptions>) => {
    return <KrpanoImage type="cylinder" {...props} />;
};

export const FisheyeImage = (props: ImageOptions<KrpanoFisheyeImageOptions>) => {
    return <KrpanoImage type="fisheye" {...props} />;
};
