import throttle from "lodash/throttle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { updateRotation } from "../../actions/rotation";
import { useAction } from "../../hooks/useAction";
import Events from "../../krpano/Events";
import { Krpano } from "../../types/krpano";

interface Props {
    disabled: boolean;
    throttleTimeout?: number;
}

const RotationListener: React.FunctionComponent<Props> = ({ disabled, throttleTimeout = 200 }) => {
    const [initialized, setInitialized] = useState(false);
    const update = useAction(updateRotation);

    const throttledViewUpdate = useRef(throttle((krpano: Krpano) => {
        update({
            horizontal: krpano.view.hlookat,
            fov: krpano.view.fov,
            vertical: krpano.view.vlookat
        });
    }, throttleTimeout));

    const handlePanoLoaded = () => {
        setInitialized(true);
    };

    const handleViewChange = useCallback((pano: Krpano) => {
        if (!initialized) {
            return;
        }
        if (disabled) {
            return;
        }
        if (throttledViewUpdate.current == null) {
            return;
        }
        throttledViewUpdate.current(pano);
    }, [disabled, initialized]);

    useEffect(() => {
        if (disabled) {
            throttledViewUpdate.current.cancel();
        }
    }, [disabled]);

    return <Events onnewpano={handlePanoLoaded} onviewchanged={handleViewChange} />;
};

export default RotationListener;
