import { Action } from "redux";

export enum IdleActions {
    IDLE_ENTER = "IDLE_ENTER",
    IDLE_LEAVE = "IDLE_LEAVE"
}

export interface IdleEnterAction extends Action<IdleActions.IDLE_ENTER> { }
export interface IdleLeaveAction extends Action<IdleActions.IDLE_LEAVE> { }

export const enterIdle = (): IdleEnterAction => ({
    type: IdleActions.IDLE_ENTER
});

export const leaveIdle = (): IdleLeaveAction => ({
    type: IdleActions.IDLE_LEAVE
});


export type IdleActionTypes = IdleEnterAction | IdleLeaveAction;
