import { Locale, Translateable, Translation } from "../reducers";

/**
 * Retrieve a translated value from a data object
 * If the provided data is not a translated object, the data itself is returned
 * If there is no configuration for a given locale, the first value will be returned
 *
 * @param data
 * @param locale
 */
export function getTranslatedValue<T = string>(data: Translateable<T>, locale: Locale): T | null {
    if (!Array.isArray(data)) {
        return data as T;
    }

    let fallback: T | null = null;
    for (const entry of data as Array<Translation<T>>) {
        if (entry.locale != null && entry.locale === locale.code) {
            return entry.value;
        }
        if (fallback == null && entry.value != null) {
            fallback = entry.value;
        }
    }

    return fallback;
}