import "react-app-polyfill/ie9";

import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/map";
import "core-js/features/set";

import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/fonts.css";
import "./assets/css/transitions.css";
import Loader from "./components/Loader";
import Provider from "./components/Provider";
import "./index.css";
import { isUnsupportedBrowser } from "./services/browser";

if (isUnsupportedBrowser) {
    const notice = document.getElementById("page-unavailable-notice");
    if (notice) {
        notice.attributes.removeNamedItem("hidden");
    }
} else {
    ReactDOM.render(<Provider component={Loader} />, document.getElementById("root"));
}
