import React from "react";
import styles from "./Spinner.module.css";

const Spinner = () => {
    return (
        <div className={styles["spinner-wrapper"]}>
            <div className={styles.spinner}>
                <div /><div /><div />
                <div /><div /><div />
                <div /><div /><div />
                <div /><div /><div />
            </div>
        </div>
    );
};

export default React.memo(Spinner);