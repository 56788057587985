import { put, select, takeEvery } from "redux-saga/effects";
import { ChapterActions, ChapterCloseAction, ChapterOpenAction } from "../actions/chapter";
import { IntroActions, IntroLeaveAction } from "../actions/intro";
import { RotationActions, RotationUpdateAction, setRotateTo } from "../actions/rotation";
import { activateTopic, deactivateTopic, TopicActions, TopicActivateAction } from "../actions/topic";
import { HotspotConfiguration } from "../data/Configuration";
import { Rotation, Topic } from "../reducers";
import { getHotspotConfiguration } from "../selectors/config";
import { getCurrentRotation, getTargetRotation } from "../selectors/rotation";
import { getTopic, getTopics } from "../selectors/topic";
import { normalizeDegrees } from "../services/math";

function* checkActiveTopic(action: RotationUpdateAction) {
    const targetRotation: boolean = yield select(getTargetRotation);
    // Skip topic check if we are rotating to a specific position
    if (targetRotation != null) {
        return;
    }

    const topics: Topic[] = yield select(getTopics);
    const activeTopic: Topic | null = yield select(getTopic);
    const config: HotspotConfiguration = yield select(getHotspotConfiguration);

    if (topics.length === 0) {
        return;
    }

    const value = normalizeDegrees(action.rotation.horizontal);
    for (const topic of topics) {
        const start = normalizeDegrees(topic.sphericalCoordinates.horizontalAngle - config.visibleRange);
        const end = normalizeDegrees(topic.sphericalCoordinates.horizontalAngle + config.visibleRange);

        if (value > start && value < end) {
            if (topic !== activeTopic) {
                yield put(activateTopic(topic));
            }
            return;
        }
    }

    if (activeTopic !== null) {
        return yield put(deactivateTopic());
    }

}

function* zoomIn(action: ChapterOpenAction) {
    if (action.updateRotation == null) {
        return;
    }
    const topic = action.chapter.topic;
    if (topic == null || topic.sphericalCoordinates == null) {
        return null;
    }
    const currentRotation: Rotation = yield select(getCurrentRotation);
    yield put(setRotateTo({
        fov: currentRotation.fov - 10,
        horizontal: topic.sphericalCoordinates.horizontalAngle + action.updateRotation.addHorizontal,
        vertical: topic.sphericalCoordinates.verticalAngle + action.updateRotation.addVertical,
    }));
}

function* zoomOut(action: ChapterCloseAction) {
    const topic: Topic | null = yield select(getTopic);
    if (topic == null || action.zoomOut === false) {
        return null;
    }
    const currentRotation: Rotation = yield select(getCurrentRotation);
    yield put(setRotateTo({
        fov: currentRotation.fov + 10,
        horizontal: topic.sphericalCoordinates.horizontalAngle || 0,
        vertical: topic.sphericalCoordinates.verticalAngle || 0,
    }));
}


function* maybeUpdateRotation(action: TopicActivateAction) {
    const currentRotation: Rotation = yield select(getCurrentRotation);
    if (action.updateRotation && action.topic.sphericalCoordinates) {
        yield put(setRotateTo({
            fov: currentRotation.fov,
            horizontal: action.topic.sphericalCoordinates.horizontalAngle || 0,
            vertical: action.topic.sphericalCoordinates.verticalAngle || 0,
        }));
    }
}

function* applyIntroRotation(action: IntroLeaveAction) {
    const currentRotation: Rotation = yield select(getCurrentRotation);
    yield put(setRotateTo({ ...currentRotation }, true));
}

export default [
    takeEvery(RotationActions.ROTATION_UPDATE, checkActiveTopic),
    takeEvery(TopicActions.TOPIC_ACTIVATE, maybeUpdateRotation),
    takeEvery(ChapterActions.CHAPTER_OPEN, zoomIn),
    takeEvery(ChapterActions.CHAPTER_CLOSE, zoomOut),
    takeEvery(IntroActions.INTRO_LEAVE, applyIntroRotation)
];