

export interface Coordinate {
    x: number;
    y: number;
}

export interface Size {
    w: number;
    h: number;
}

/**
 * Find the biggest gap between two numbers and return both
 * 
 * @param items 
 */
export const findBiggestGap = (items: number[]): [number, number] => {
    const sortedItems = items.sort((a, b) => a - b);
    let biggestGap: number = 0;
    let biggestGapIndex: number = 0;
    for (let i = 0; i < sortedItems.length - 1; i++) {
        const gap = sortedItems[i + 1] - sortedItems[i];
        if (gap > biggestGap) {
            biggestGap = gap;
            biggestGapIndex = i;
        }
    }

    return [sortedItems[biggestGapIndex], sortedItems[biggestGapIndex + 1]];
};


/**
 * Converts degree to radians
 * 
 * @param deg 
 */
export const degreeToRadians = (deg: number): number => {
    return deg * (Math.PI / 180);
};


/**
 * Normalizes a degree value in a range from 0 - 360
 * @param value 
 */
export const normalizeDegrees = (value: number): number => {
    let x = value % 360;
    if (x < 0) {
        x += 360;
    }
    return x;
};

/**
 * Converts polar coordinates to cartesian coordinates
 * 
 * @param center 
 * @param radius 
 * @param angleInDegrees 
 */
export const polarToCartesian = (center: Coordinate, radius: number, angleInDegrees: number): Coordinate => {
    const angleInRadians = degreeToRadians(angleInDegrees - 90);
    return {
        x: center.x + radius * Math.cos(angleInRadians),
        y: center.y + radius * Math.sin(angleInRadians)
    };
};

/**
 * Retrieve the centroid of a circular sector
 * 
 * @param radius 
 * @param angle 
 */
export const centroidOfCircularSector = (radius: number, angle: number): number => {
    const halfRadians = degreeToRadians(angle / 2);
    return (2 * radius * Math.sin(halfRadians)) / (3 * halfRadians);
};

/**
 * Retrieve the centroid of a circular segment
 * @param radius 
 * @param angle 
 */
export const centroidOfCircularSegment = (radius: number, angle: number): number => {
    const halfRadians = degreeToRadians(angle / 2);
    return (4 * radius * Math.pow(Math.sin(halfRadians), 3)) / (3 * (2 * halfRadians - Math.sin(2 * halfRadians)));
};

/**
 * Calculate the distance betwen two points
 * @param a 
 * @param b 
 */
export const distanceBetweenTwoPoints = (a: Coordinate, b: Coordinate): number => {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
};

/**
 * Describes a slice of a circle as svg path
 * 
 * @param center 
 * @param radius 
 * @param spread 
 * @param startAngleInDegrees 
 * @param endAngleInDegrees 
 * @param padding
 */
export const describeSlice = (
    center: Coordinate,
    radius: number,
    spread: number,
    startAngleInDegrees: number,
    endAngleInDegrees: number,
    padding: number = 0
): string => {
    if (padding > 0) {
        // This is not correct
        radius -= padding;
        if (Math.abs(spread) >= radius) {
            spread = spread > 0 ? spread - padding : spread + padding;
        }
        // Calculate the center point including the padding
        const middleAngle = (startAngleInDegrees + endAngleInDegrees) / 2;
        center = polarToCartesian(center, padding, middleAngle);
    }

    const innerStart = polarToCartesian(center, radius, endAngleInDegrees);
    const innerEnd = polarToCartesian(center, radius, startAngleInDegrees);
    const outerStart = polarToCartesian(center, radius + spread, endAngleInDegrees);
    const outerEnd = polarToCartesian(center, radius + spread, startAngleInDegrees);

    const largeArcFlag = endAngleInDegrees > startAngleInDegrees
        ? (endAngleInDegrees - startAngleInDegrees <= 180 ? "0" : "1")
        : (startAngleInDegrees - endAngleInDegrees <= 180 ? "1" : "0");

    const d = [
        "M", outerStart.x, outerStart.y,
        "A", radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
        "L", innerEnd.x, innerEnd.y,
        "A", radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
        "L", outerStart.x, outerStart.y, "Z"
    ].join(" ");

    return d;
};