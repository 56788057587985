import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIdle } from "../hooks/useIdle";
import { useStateSelector } from "../hooks/useStateSelector";
import { getIdleConfiguration } from "../selectors/config";
import { isIntroActive } from "../selectors/intro";
import logger from "../services/logger";

interface Props {
    events?: string[];
}

const DEFAULT_EVENTS = [
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mouseWheel",
    "mousedown",
    "touchstart",
    "pointerdown",
    "MSPointerDown",
    "MSPointerMove"
];

const IdleHandler: React.FunctionComponent<Props> = ({ events = DEFAULT_EVENTS }) => {
    const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);
    const [actionTime, setActionTime] = useState(Date.now());
    const { isIdle, enterIdle, leaveIdle } = useIdle();
    const introActive = useStateSelector(isIntroActive);
    const config = useStateSelector(getIdleConfiguration);
    const timerRef = useRef<number | null>(null);

    const handleIdle = () => {
        if (introActive) {
            logger.warn("Find out why this is called. Timer should be stopped.");
            return;
        }
        enterIdle();
    };

    const resetTimer = () => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(handleIdle, config.timeout);
    };

    const onBlur = () => {
        if (document.activeElement == null) {
            setIframe(null);
            return;
        }
        if (document.activeElement.tagName.toLowerCase() !== "iframe") {
            setIframe(null);
            return;
        }
        setIframe((document.activeElement as HTMLIFrameElement));
    };

    const handleAction = useCallback(() => setActionTime(Date.now()), []);

    useEffect(() => {
        if (iframe && iframe.contentDocument) {
            for (const event of events) {
                iframe.contentDocument.addEventListener(event, handleAction);
            }
        }
        /* return () => {
            if (iframe) {
                for (const event of events) {
                    iframe.removeEventListener(event, resetTimer);
                }
            }
        }; */
    }, [iframe, events]);

    useEffect(() => {
        window.addEventListener("blur", onBlur);
        for (const event of events) {
            window.addEventListener(event, handleAction, true);
        }

        handleAction();
        return () => {
            window.removeEventListener("blur", onBlur);
            for (const event of events) {
                window.removeEventListener(event, handleAction, true);
            }
        };
    }, []);

    useEffect(() => {
        if (isIdle) {
            leaveIdle();
        } else {
            resetTimer();
        }
    }, [actionTime]);

    useEffect(() => {
        if (introActive) {
            logger.info("pause idle timer.");
            if (timerRef.current) {
                window.clearTimeout(timerRef.current);
            }
            timerRef.current = null;
        } else {
            logger.info("resume idle timer.");
            resetTimer();
        }
    }, [introActive, isIdle, timerRef]);

    return null;
};

export default React.memo(IdleHandler);
