import { createSelector } from "reselect";
import { ApplicationState, Locale, TranslateableList } from "../reducers";
import { getTranslatedValue } from "../services/locale";

export const getLocale = (state: ApplicationState): Locale => {
    return state.locale;
};

export const getLocales = (state: ApplicationState): Locale[] => {
    return state.locales;
};

export const getTranslations = (state: ApplicationState): TranslateableList<string> => {
    return state.translations;
};

export const getTranslation = (state: ApplicationState, key: string, fallback: string): string => {
    const translations = getTranslations(state);
    const locale = getLocale(state);
    if (translations[key] == null) {
        return fallback;
    }
    return getTranslatedValue(translations[key], locale) || fallback;
};