import { Action } from "redux";

export enum FileActions {
    FILE_REQUEST = "FILE_REQUEST",
    FILE_FOUND = "FILE_FOUND",
    FILE_ERROR = "FILE_ERROR"
}

export type FileCategory = "json" | "html";
export type FileContentType<T extends FileCategory> = (T extends "html" ? string : object) | null;


export interface FileRequestAction extends Action<FileActions.FILE_REQUEST> {
    file: string;
    category: FileCategory;
}

export interface FileFoundAction<T extends FileCategory = "html"> extends Action<FileActions.FILE_FOUND> {
    file: string;
    category: T;
    contents: FileContentType<T>;
}

export interface FileErrorAction extends Action<FileActions.FILE_ERROR> {
    file: string;
    category: FileCategory;
    reason: string;
}

export const requestFile = (file: string, category: FileCategory): FileRequestAction => ({
    type: FileActions.FILE_REQUEST,
    file,
    category
});

export const foundFile = <T extends FileCategory>(file: string, category: T, contents: any): FileFoundAction<T> => ({
    type: FileActions.FILE_FOUND,
    file,
    category,
    contents
});

export const fileError = (file: string, category: FileCategory, reason: string): FileErrorAction => ({
    type: FileActions.FILE_ERROR,
    file,
    category,
    reason
});

export type FileActionTypes = FileRequestAction
    | FileFoundAction
    | FileErrorAction;