import { Action } from "redux";

export enum LoadActions {
    LOAD_REQUEST = "LOAD_REQUEST",
    LOAD_START = "LOAD_START",
    LOAD_COMPLETE = "LOAD_COMPLETE",
    LOAD_PROGRESS = "LOAD_PROGRESS",
    LOAD_FAILURE = "LOAD_FAILURE",
    LOAD_INVALIDATE = "LOAD_INVALIDATE"
}

export enum LoadTarget {
    CONFIG = "CONFIG",
    CONTENT = "CONTENT"
}

export type LoadTargetType = LoadTarget | string;

export interface LoadStartAction extends Action<LoadActions.LOAD_START> {
    target: LoadTargetType;
}

export interface LoadCompleteAction extends Action<LoadActions.LOAD_COMPLETE> {
    target: LoadTargetType;
}

export interface LoadFailureAction extends Action<LoadActions.LOAD_FAILURE> {
    reason: string;
    target: LoadTargetType;
}

export interface LoadProgressAction extends Action<LoadActions.LOAD_PROGRESS> {
    total: number;
    loaded: number;
    target: LoadTargetType;
}

export interface LoadInvalidateAction extends Action<LoadActions.LOAD_INVALIDATE> {
    target: LoadTargetType;
}

export const startLoading = (target: LoadTargetType): LoadStartAction => ({
    type: LoadActions.LOAD_START,
    target
});

export const invalidateLoading = (target: LoadTargetType): LoadStartAction => ({
    type: LoadActions.LOAD_START,
    target
});

export const completeLoading = (target: LoadTargetType): LoadCompleteAction => ({
    type: LoadActions.LOAD_COMPLETE,
    target
});

export const failedLoading = (target: LoadTargetType, reason: string): LoadFailureAction => ({
    type: LoadActions.LOAD_FAILURE,
    reason,
    target
});

export const progressLoading = (target: LoadTargetType, loaded: number, total: number): LoadProgressAction => ({
    type: LoadActions.LOAD_PROGRESS,
    loaded,
    total,
    target
});


export type LoadActionTypes = LoadStartAction
    | LoadProgressAction
    | LoadCompleteAction
    | LoadFailureAction
    | LoadInvalidateAction;