import React, { useEffect, useState } from "react";
import { useStateSelector } from "../../hooks/useStateSelector";
import { Topic } from "../../reducers";
import { getRoomConfiguration } from "../../selectors/config";
import { getTopic, getTopics } from "../../selectors/topic";
import TopicOverlay from "./TopicOverlay";

const Overlay = () => {
    const [render, setRender] = useState(false);
    const config = useStateSelector(getRoomConfiguration);
    const activeTopic = useStateSelector(getTopic);
    const topics = useStateSelector(getTopics);

    useEffect(() => {
        const timeoutId = window.setTimeout(() => setRender(true), config.lightOverlayRenderingDelay);
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, []);

    return render ? (
        <React.Fragment>
            {topics.map((topic: Topic, i: number) => <TopicOverlay
                fadeDelay={config.lightOverlayDisplayDelay}
                fadeDuration={config.lightOverlayFadeDuration}
                key={i}
                topic={topic}
                active={activeTopic === topic}
            />)}
        </React.Fragment>
    ) : null;
};

export default React.memo(Overlay);

