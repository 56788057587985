import { Action } from "redux";
import { Chapter } from "../reducers";

export enum ChapterActions {
    CHAPTER_OPEN = "CHAPTER_OPEN",
    CHAPTER_CLOSE = "CHAPTER_CLOSE"
}

interface UpdateRotationOptions {
    addHorizontal: number;
    addVertical: number;
}

export interface ChapterOpenAction extends Action<ChapterActions.CHAPTER_OPEN> {
    chapter: Chapter;
    updateRotation?: UpdateRotationOptions;
}

export interface ChapterCloseAction extends Action<ChapterActions.CHAPTER_CLOSE> {
    zoomOut: boolean;
}


export const openChapter = (chapter: Chapter, updateRotation?: UpdateRotationOptions): ChapterOpenAction => ({
    type: ChapterActions.CHAPTER_OPEN,
    updateRotation,
    chapter
});

export const closeChapter = (zoomOut: boolean = true): ChapterCloseAction => ({
    type: ChapterActions.CHAPTER_CLOSE,
    zoomOut
});

export type ChapterActionTypes = ChapterOpenAction | ChapterCloseAction;
