import { Action } from "redux";
import { Configuration } from "../data/Configuration";

export enum ConfigurationActions {
    CONFIGURATION_UPDATE = "CONFIGURATION_UPDATE"
}

export interface ConfigurationUpdateAction extends Action<ConfigurationActions.CONFIGURATION_UPDATE> {
    config: Partial<Configuration>;
}

export const updateConfiguration = (config: Partial<Configuration>): ConfigurationUpdateAction => ({
    type: ConfigurationActions.CONFIGURATION_UPDATE,
    config
});

export type ConfigurationActionTypes = ConfigurationUpdateAction;
