import { LoadingState } from ".";
import { LoadActions, LoadActionTypes, LoadTarget, LoadTargetType } from "../actions/load";

export const defaultLoadingState: LoadingState = {
    finished: false,
    started: false,
    progress: {
        loaded: 0,
        total: 0
    },
    error: null
};

const defaultMap: Record<LoadTargetType, LoadingState> = {
    [LoadTarget.CONFIG]: { ...defaultLoadingState },
    [LoadTarget.CONTENT]: { ...defaultLoadingState }
};

export const loadReducer = (
    state: Record<LoadTargetType, LoadingState> = defaultMap,
    action: LoadActionTypes
): Record<LoadTargetType, LoadingState> => {
    switch (action.type) {
        case LoadActions.LOAD_START:
            return {
                ...state,
                [action.target]: {
                    error: null,
                    started: true,
                    finished: false,
                    progress: {
                        loaded: 0,
                        total: 0
                    }
                }
            };

        case LoadActions.LOAD_PROGRESS:
            return {
                ...state,
                [action.target]: {
                    ...state[action.target],
                    progress: {
                        loaded: action.loaded,
                        total: action.total
                    }
                }
            };

        case LoadActions.LOAD_FAILURE:
            return {
                ...state,
                [action.target]: {
                    ...state[action.target],
                    error: action.reason,
                    finished: true
                }
            };

        case LoadActions.LOAD_COMPLETE:
            return {
                ...state,
                [action.target]: {
                    ...state[action.target],
                    finished: true
                }
            };

        case LoadActions.LOAD_INVALIDATE:
            return {
                ...state,
                [action.target]: {
                    ...state[action.target],
                    ...defaultLoadingState
                }
            };

        default:
            return state;
    }
};