import chroma from "chroma-js";
import uniqueId from "lodash/uniqueId";
import { Topic, TopicColorTheme, TopicType } from ".";
import { TopicActions, TopicActionTypes } from "../actions/topic";
import logger from "../services/logger";

export const topicReducer = (state: Topic | null = null, action: TopicActionTypes): Topic | null => {
    switch (action.type) {
        case TopicActions.TOPIC_ACTIVATE:
            return action.topic;

        case TopicActions.TOPIC_DEACTIVATE:
            return null;

        case TopicActions.TOPICS_UPDATE:
            return (state != null && action.topics.includes(state))
                ? state
                : null;

        default:
            return state;
    }
};

const validTopic = (data: Partial<Topic>, i: number): Topic | null => {
    if (data.name == null) {
        logger.warn(`Invalid topic at index ${i}: Missing property name`);
        return null;
    }

    if (data.color == null) {
        logger.warn(`Invalid topic at index ${i}: Missing property color`);
        return null;
    }

    return {
        chapters: [],
        color: "#FF00FF",
        icon: "default.svg",
        name: uniqueId("topic-"),
        type: TopicType.HOTSPOT,
        ...data,
        sphericalCoordinates: {
            horizontalAngle: 0,
            verticalAngle: 0,
            ...data.sphericalCoordinates
        },
        theme: {
            ...generateTheme(data.color),
            ...data.theme
        }
    };
};

const generateTheme = (color: string = "#FF00FF"): TopicColorTheme => {
    const bgColor = chroma(color).darken(2.25).desaturate(0.4).alpha(0.5);
    const borderColor = chroma(color);
    return {
        fadedColor: borderColor.alpha(0.5).css(),
        contentBackgroundColor: bgColor.css(),
        contentBorderColor: borderColor.css(),
        iconBackgroundColor: bgColor.alpha(0.8).css(),
        closeButtonBackgroundColor: bgColor.alpha(1).css(),
        closeButtonBorderColor: borderColor.css(),
        overlayColor: bgColor.darken(1.5).css()
    };
};

const assignTopicToChapters = (topic: Topic): Topic => {
    for (const chapter of topic.chapters) {
        chapter.topic = topic;
    }
    return topic;
};

const validTopics = (data: Array<Partial<Topic>>): Topic[] => {
    const result: Topic[] = [];
    for (let i = 0; i < data.length; i++) {
        const topic = validTopic(data[i], i);
        if (topic != null) {
            assignTopicToChapters(topic);
            result.push(topic);
        }
    }
    return result;
};

export const topicsReducer = (state: Topic[] = [], action: TopicActionTypes): Topic[] => {
    switch (action.type) {
        case TopicActions.TOPICS_UPDATE:
            return validTopics(action.topics);

        default:
            return state;
    }
};
