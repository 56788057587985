import { Action } from "redux";
import { Topic } from "../reducers";

export enum TopicActions {
    TOPICS_UPDATE = "TOPICS_UPDATE",

    TOPIC_ACTIVATE = "TOPIC_ACTIVATE",
    TOPIC_DEACTIVATE = "TOPIC_DEACTIVATE"
}

export interface TopicsUpdateAction extends Action<TopicActions.TOPICS_UPDATE> {
    topics: Topic[];
}

export interface TopicActivateAction extends Action<TopicActions.TOPIC_ACTIVATE> {
    topic: Topic;
    updateRotation: boolean;
}

export interface TopicDeactivateAction extends Action<TopicActions.TOPIC_DEACTIVATE> { }

export const activateTopic = (topic: Topic, updateRotation: boolean = false): TopicActivateAction => ({
    type: TopicActions.TOPIC_ACTIVATE,
    topic,
    updateRotation
});

export const deactivateTopic = (): TopicDeactivateAction => ({
    type: TopicActions.TOPIC_DEACTIVATE
});

export const updateTopics = (topics: Topic[]): TopicsUpdateAction => ({
    type: TopicActions.TOPICS_UPDATE,
    topics
});


export type TopicActionTypes = TopicActivateAction | TopicDeactivateAction | TopicsUpdateAction;
