import React from "react";
import { Coordinate, describeSlice, Size } from "../../services/math";
import styles from "./LocationIndicator.module.css";


interface Props {
    circleCenter: Coordinate;
    radius: number;
    spread: number;
    sliceSpread: Size;
    gap: [number, number];
}

const InnerRing = ({ gap, circleCenter, sliceSpread, radius, spread }: Props) => {
    const [gapLowerBounds, gapHigherBounds] = gap;
    const path = describeSlice(circleCenter, radius, -spread, gapHigherBounds - sliceSpread.w, gapLowerBounds + sliceSpread.w);

    return (
        <path
            className={styles["inner-circle-slice"]}
            d={path}
        />
    );
};

export default React.memo(InnerRing);


