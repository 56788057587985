import { Chapter, Topic } from ".";
import { ChapterActions, ChapterActionTypes } from "../actions/chapter";
import { IdleActions, IdleActionTypes } from "../actions/idle";
import { TopicActions, TopicActionTypes } from "../actions/topic";

/**
 * Checks if a topic includes a given chapter
 * 
 * @param topic
 * @param chapter 
 */
const topicHasChapter = (topic: Topic, chapter: Chapter): boolean => {
    return topic.chapters.includes(chapter);
};

/**
 * Checks if any of the given topics includes the given chapter
 * 
 * @param topics
 * @param chapter 
 */
const topicsHaveChapter = (topics: Topic[], chapter: Chapter): boolean => {
    for (const topic of topics) {
        if (topicHasChapter(topic, chapter)) {
            return true;
        }
    }
    return false;
};

export const chapterReducer = (
    state: Chapter | null = null,
    action: ChapterActionTypes | TopicActionTypes | IdleActionTypes
): Chapter | null => {
    switch (action.type) {
        case ChapterActions.CHAPTER_OPEN:
            return action.chapter;

        case IdleActions.IDLE_ENTER:
        case TopicActions.TOPIC_DEACTIVATE:
        case ChapterActions.CHAPTER_CLOSE:
            return null;

        case TopicActions.TOPICS_UPDATE:
            if (state != null && !topicsHaveChapter(action.topics, state)) {
                return null;
            }
            return state;

        default:
            return state;
    }
};

