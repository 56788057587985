import React from "react";
import { useMappedStateSelectors } from "../../hooks/useMappedStateSelectors";
import { Topic } from "../../reducers";
import { isIdle } from "../../selectors/idle";
import { getHotspotTopics, getTopic } from "../../selectors/topic";
import TopicHotspot from "./TopicHotspot";

interface State {
    topics: Topic[];
    idle: boolean;
    activeTopic: Topic | null;
}

const Hotspots: React.FunctionComponent = () => {
    const { topics, activeTopic, idle } = useMappedStateSelectors<State>({
        topics: getHotspotTopics,
        activeTopic: getTopic,
        idle: isIdle
    });

    return (
        <React.Fragment>
            {topics.map((topic: Topic, i: number) => <TopicHotspot idle={idle} key={i} topic={topic} active={topic === activeTopic} />)}
        </React.Fragment>
    );
};

export default Hotspots;
