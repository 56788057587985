import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { Component, HTMLProps } from "react";
import { EmbedPanoParameters, Krpano as KrpanoInterface, KrpanoElementInterface } from "../types/krpano";
import View from "./View";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface State {
    krpano: KrpanoInterface | null;
}

type Props = Omit<EmbedPanoParameters, "target"> & {
    wrapper?: HTMLProps<HTMLDivElement>;
    children?: React.ReactNode;
};

class Krpano extends React.PureComponent<Props, State> {

    private containerId: string = uniqueId("krpano-");

    constructor(props: Props) {
        super(props);

        this.state = {
            krpano: null
        };
    }

    private handleReady = (pano: KrpanoElementInterface) => {
        if (this.props.onready) {
            this.props.onready(pano);
        }

        /* pano.get("global").contextmenu.fullscreen = false;
        pano.get("global").contextmenu.versioninfo = false; */

        this.setState({
            krpano: pano.get("global")
        });
    }

    public componentDidMount(): void {
        const { children, wrapper, ...props } = this.props;
        window.embedpano({
            html5: "only",
            xml: null,
            ...props,
            target: this.containerId,
            onready: this.handleReady
        });
    }

    public componentWillUnmount(): void {
        // @TODO
        // window.removepano(this.containerId);
    }

    private hasViewNode(): boolean {
        const { children } = this.props;
        const result = React.Children.toArray(children).find((element) => {
            if ((element as any).type === View) {
                return true;
            }
            return false;
        });
        return result != null;
    }

    public render(): React.ReactNode {
        const { className = "krpano-wrapper", ...rest } = this.props.wrapper || {};
        return (
            <div id={this.containerId} className={classNames(className, { ready: this.state.krpano != null })} {...rest}>
                <KrpanoContext.Provider value={this.state.krpano}>
                    {this.props.children}
                </KrpanoContext.Provider>
            </div>
        );
    }
}

export const KrpanoContext = React.createContext<KrpanoInterface | null>(null);
export default Krpano;
