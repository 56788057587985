import React from "react";
import { useTranslation } from "../../hooks/useTranslations";

interface Props<T extends HTMLElement = HTMLElement> extends React.HTMLAttributes<T> {
    name: string;
    fallback?: string;
    wrapper?: React.ReactType;
}

const Translation = ({ name, fallback = name, wrapper: Wrapper = "span", ...wrapperProps }: Props) => {
    const translation = useTranslation(name, fallback);
    return <Wrapper {...wrapperProps} dangerouslySetInnerHTML={{ __html: translation }} />;
};

export default React.memo(Translation);