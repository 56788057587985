import random from "lodash/random";
import React from "react";
import { buildPublicPath } from "../../services/path";
import styles from "./HotspotRings.module.css";

const HotspotRings = () => {
    const animationStyleFactory = (from: number, to: number) => ({
        animationDuration: random(from, to, true) + "s"
    });

    return (
        <div className={styles.container}>
            <img className={styles["ring-1"]} style={animationStyleFactory(6, 8)} src={buildPublicPath("content/icons/ring-1.svg")} />
            <img className={styles["ring-2"]} style={animationStyleFactory(4.5, 6)} src={buildPublicPath("content/icons/ring-2.svg")} />
            <img className={styles["ring-3"]} style={animationStyleFactory(3, 4.5)} src={buildPublicPath("content/icons/ring-3.svg")} />
        </div>
    );
};

export default React.memo(HotspotRings);