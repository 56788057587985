import { ConfigurationActions, ConfigurationActionTypes } from "../actions/config";
import { IdleActions, IdleActionTypes } from "../actions/idle";
import { IntroActions, IntroActionTypes } from "../actions/intro";
import { defaultConfiguration } from "./config";

export const introReducer = (
    state: boolean = Boolean(defaultConfiguration.intro.enabled && defaultConfiguration.intro.playOnStart),
    action: ConfigurationActionTypes | IntroActionTypes | IdleActionTypes
): boolean => {
    switch (action.type) {
        case IntroActions.INTRO_ENTER:
            return true;

        case IntroActions.INTRO_LEAVE:
            return false;

        default:
            return state;
    }
};

