import uniqueId from "lodash/uniqueId";
import React, { useContext, useEffect, useMemo } from "react";
import { Krpano } from "../types/krpano";
import { KrpanoContext } from "./Krpano";

interface Props {
    keep?: boolean;
    onexitfullscreen?: (krpano: Krpano) => void;
    onxmlcomplete?: (krpano: Krpano) => void;
    onpreviewcomplete?: (krpano: Krpano) => void;
    onloadcomplete?: (krpano: Krpano) => void;
    onnewpano?: (krpano: Krpano) => void;
    onremovepano?: (krpano: Krpano) => void;
    onnewscene?: (krpano: Krpano) => void;
    onxmlerror?: (krpano: Krpano) => void;
    onloaderror?: (krpano: Krpano) => void;
    onkeydown?: (krpano: Krpano) => void;
    onkeyup?: (krpano: Krpano) => void;
    onclick?: (krpano: Krpano) => void;
    onsingleclick?: (krpano: Krpano) => void;
    ondoubleclick?: (krpano: Krpano) => void;
    onmousedown?: (krpano: Krpano) => void;
    onmouseup?: (krpano: Krpano) => void;
    onmousewheel?: (krpano: Krpano) => void;
    oncontextmenu?: (krpano: Krpano) => void;
    onidle?: (krpano: Krpano) => void;
    onviewchange?: (krpano: Krpano) => void;
    onviewchanged?: (krpano: Krpano) => void;
    onresize?: (krpano: Krpano) => void;
    onautorotatestart?: (krpano: Krpano) => void;
    onautorotatestop?: (krpano: Krpano) => void;
    onautorotateoneround?: (krpano: Krpano) => void;
    onautorotatechange?: (krpano: Krpano) => void;
    gyro_onavailable?: (krpano: Krpano) => void;
    gyro_onunavailable?: (krpano: Krpano) => void;
    gyro_onenable?: (krpano: Krpano) => void;
    gyro_ondisable?: (krpano: Krpano) => void;
    webvr_onavailable?: (krpano: Krpano) => void;
    webvr_onunavailable?: (krpano: Krpano) => void;
    webvr_onunknowndevice?: (krpano: Krpano) => void;
    webvr_onentervr?: (krpano: Krpano) => void;
    webvr_onexitvr?: (krpano: Krpano) => void;
}

const Events = ({ keep = true, ...events }: Props) => {
    const id = useMemo(() => uniqueId("events-"), []);
    const context = useContext(KrpanoContext);

    const cleanupEvents = () => {
        if (context == null) {
            return;
        }
        context.events.removeItem(id);
    };

    const updateEvents = () => {
        if (context == null) {
            return;
        }

        const item = context.events.getItem(id);
        const krpanoEvents = item == null ? context.events.createItem(id) : item;
        krpanoEvents.keep = keep;

        Object.keys(events).forEach((key: string) => {
            if ((events as any)[key] == null) {
                (events as any)[key as any] = null;
            } else {
                krpanoEvents[key as keyof Props] = () => {
                    (events as any)[key](context);
                };
            }
        });
    };

    useEffect(updateEvents);
    useEffect(() => cleanupEvents, [context]);
    return null;
};

export default React.memo(Events);
