import React, { useContext, useEffect, useState } from "react";
import { KrpanoViewOptions } from "../types/krpano";
import Events from "./Events";
import { KrpanoContext } from "./Krpano";

const View = (props: Partial<KrpanoViewOptions>) => {
    const [initialized, setInitialized] = useState(false);
    const context = useContext(KrpanoContext);
    const updateView = () => {
        if (context == null || context.view == null) {
            return;
        }

        const { hlookat, vlookat, fov, ...rest } = props;
        Object.keys(initialized ? rest : props).forEach((key: string) => {
            context.view[key as keyof KrpanoViewOptions] = (props as KrpanoViewOptions)[key as keyof KrpanoViewOptions];
        });

        if (!initialized) {
            setInitialized(true);
        }
    };

    useEffect(updateView);
    return <Events onnewpano={updateView} />;
};

View.defaultProps = {
    hlookat: 0,
    vlookat: 0,
    fovmin: 40,
    fovmax: 130,
    limitview: "auto"
};

export default React.memo(View);
