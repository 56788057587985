import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { sprintf } from "sprintf-js";
import { useLocale } from "../../hooks/useLocale";
import { useTranslatedValue } from "../../hooks/useTranslatedValue";
import { SlideshowChapter, SlideshowSource } from "../../reducers";
import Button from "../Button";
import SwiperCarousel from "../slideshow/SwiperCarousel";
import styles from "./SlideshowContent.module.css";

interface Props {
    chapter: SlideshowChapter;
}

const SlideshowContent: React.FunctionComponent<Props> = ({ chapter }) => {
    const [locale] = useLocale();
    const [index, setIndex] = useState(0);
    const source = useTranslatedValue<SlideshowSource | null>(chapter.content.source, null);

    const paths: string[] = useMemo(() => {
        if (source == null) {
            return [];
        }

        const start = source.start || 0;
        const end = start + source.count - 1;
        const result: string[] = [];
        for (let i = start; i <= end; i++) {
            result.push(sprintf(source.pathPattern, { index: i, locale: locale.code }));
        }

        return result;
    }, [source]);

    const next = () => setIndex(index + 1);
    const prev = () => setIndex(index - 1);

    const hasPrev = index > 0 && paths.length > 1;
    const hasNext = index - 1 < paths.length && paths.length > 1;

    if (source == null) {
        return null;
    }

    return (
        <div className={styles["slideshow-content"]}>
            <SwiperCarousel updateIndex={setIndex} index={index}>
                {paths.map((path: string) => <div key={path} className="swiper-slide"><img src={path} /></div>)}
            </SwiperCarousel>
            {paths.length > 1 && chapter.content.indicator
                ? <div className={styles["slideshow-indicator"]}>
                    <span className={styles["slideshow-indicator-current"]}>{index + 1}</span>
                    <span className={styles["slideshow-indicator-separator"]}>/</span>
                    <span className={styles["slideshow-indicator-total"]}>{paths.length}</span>
                </div>
                : null}

            {paths.length > 1 && chapter.content.controls
                ? <React.Fragment>
                    <Button disabled={!hasPrev} className={classNames(styles["slideshow-control"], "prev")} onTap={prev}>
                        <svg viewBox="0 0 100 100">
                            <line x1="65" x2="35" y1="10" y2="50" />
                            <line x1="35" x2="65" y1="50" y2="90" />
                        </svg>
                    </Button>
                    <Button disabled={!hasNext} className={classNames(styles["slideshow-control"], "next")} onTap={next}>
                        <svg viewBox="0 0 100 100">
                            <line x1="35" x2="65" y1="10" y2="50" />
                            <line x1="65" x2="35" y1="50" y2="90" />
                        </svg>
                    </Button>
                </React.Fragment>
                : null}
        </div>
    );
};

export default SlideshowContent;
