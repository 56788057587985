import { IdleActions, IdleActionTypes } from "../actions/idle";

export const idleReducer = (state: boolean = false, action: IdleActionTypes): boolean => {
    switch (action.type) {
        case IdleActions.IDLE_ENTER:
            return true;

        case IdleActions.IDLE_LEAVE:
            return false;

        default:
            return state;
    }
};

