import uniqueId from "lodash/uniqueId";
import React, { useContext, useEffect, useRef } from "react";
import logger from "../services/logger";
import { KrpanoPluginElement, KrpanoPluginOptions, KrpanoVideoPluginOptions } from "../types/krpano";
import { KrpanoContext } from "./Krpano";

const Plugin = <T extends KrpanoPluginOptions>(props: Partial<T> & { id?: string }) => {
    const id = useRef<string>(props.id ? props.id : uniqueId("pano-"));
    const context = useContext(KrpanoContext);

    const cleanupPlugin = () => {
        if (context == null) {
            return;
        }
        context.removeplugin(id.current);
    };

    const updatePlugin = () => {
        if (context == null) {
            return;
        }

        const plugin: KrpanoPluginElement<T> = context.addplugin(id.current);
        plugin.keep = props.keep || true;

        Object.keys(props).forEach((key: string) => {
            (plugin as any)[key] = props[key as keyof T];
        });
    };

    useEffect(updatePlugin);
    useEffect(() => cleanupPlugin, [context]);
    return null;
};

export const KrpanoPlugin = React.memo(Plugin);

export const VideoPlugin = React.memo((props: Partial<KrpanoVideoPluginOptions>) => {
    if (props.videourl == null) {
        logger.warn("Missing videourl property for VideoPlugin");
        return null;
    }

    const options: KrpanoVideoPluginOptions = {
        url: "krpano/plugins/videoplayer.js",
        name: "video",
        ...props,
        videourl: props.videourl // not required, but solves an ts compiler issue
    };

    return <Plugin<KrpanoVideoPluginOptions> id={"video"} {...options} />;
});
