import classNames from "classnames";
import React from "react";
import { CSSTransition } from "react-transition-group";
import { enterIdle } from "../actions/idle";
import { enterIntro } from "../actions/intro";
import { useAction } from "../hooks/useAction";
import { useStateSelector } from "../hooks/useStateSelector";
import { getStaticUIConfiguration } from "../selectors/config";
import { isIdle } from "../selectors/idle";
import { isIntroActive } from "../selectors/intro";
import LocationIndicator from "./indicator/LocationIndicator";
import LanguageSelection from "./language-selection/LanguageSelection";
import styles from "./StaticUI.module.css";
import TopicMenu from "./TopicMenu";

const StaticUI = () => {
    const idle = useStateSelector(isIdle);
    const intro = useStateSelector(isIntroActive);
    const startIntro = useAction(enterIntro);
    const startIdle = useAction(enterIdle);
    const config = useStateSelector(getStaticUIConfiguration);

    return (
        <CSSTransition timeout={600} mountOnEnter={true} unmountOnExit={true} classNames="fade" in={!idle && !intro}>
            <div>
                <div key={"left"} className={classNames(styles["static-ui-container"], "left", config.align)}>
                    <TopicMenu />
                    <LanguageSelection />
                </div>

                <div key={"right"} className={classNames(styles["static-ui-container"], "right", config.align)}>
                    <LocationIndicator />
                </div>

                <div className={styles["intro-button"]} onClick={startIntro}>INTRO</div>
                <div className={styles["idle-button"]} onClick={startIdle}>IDLE</div>
            </div>
        </CSSTransition>
    );
};

export default React.memo(StaticUI);
