import merge from "lodash/merge";
import { ConfigurationActions, ConfigurationActionTypes } from "../actions/config";
import { Configuration } from "../data/Configuration";

export const defaultConfiguration: Configuration = {
    staticUI: {
        align: "top"
    },
    intro: {
        enabled: true,
        video: "content/intro/intro.mp4",
        playOnStart: true,
        view: {
            fov: 65,
            limitview: "lookat",
            fovmin: 40,
            fovmax: 90,
            vlookatmin: -30,
            vlookatmax: 30
        }
    },
    idle: {
        timeout: 90 * 1000,
        renderStaticUI: false,
        autorotate: {
            speed: 4
        }
    },
    room: {
        image: "content/pano/pano_%s.jpg",
        previewImage: "content/pano/preview.jpg",
        lightOverlayDisplayDelay: 1500,
        lightOverlayRenderingDelay: 1000,
        lightOverlayFadeDuration: 1500,
        view: {
            fov: 65,
            limitview: "lookat",
            maxpixelzoom: 2,
            fovmax: 90,
            vlookatmin: -30,
            vlookatmax: 30
        }
    },
    hotspots: {
        visibleRange: 15,
        chapterMenuItemOffset: 50,
        chapterMenuRadius: 130
    },
    indicator: {
        backgroundCircleRadius: 100,
        innerRingRadius: 70,
        innerRingWidth: 6,
        outerRingRadius: 85,
        outerRingWidth: 8,
        pointRadius: 6,
        pointAlignCircleRadius: 45
    },
    languageSelection: {
        variant: "stacked",
        circleRadius: 100,
        slicePadding: 5,
        rotationOffset: 90
    }
};

export const configReducer = (state: Configuration = defaultConfiguration, action: ConfigurationActionTypes): Configuration => {
    switch (action.type) {
        case ConfigurationActions.CONFIGURATION_UPDATE:
            return merge({}, state, defaultConfiguration, action.config);

        default:
            return state;
    }
};