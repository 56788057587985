import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { requestFile } from "../../actions/file";
import { useAction } from "../../hooks/useAction";
import { useStateSelector } from "../../hooks/useStateSelector";
import { useTranslatedValue } from "../../hooks/useTranslatedValue";
import { TranslateableString } from "../../reducers";
import { getFileContents, getFileError, isFileRequested } from "../../selectors/files";
import logger from "../../services/logger";
import Spinner from "../Spinner";

interface Props {
    file: TranslateableString;
    wrapper?: React.ReactType;
}

const LazyHtmlContent: React.FunctionComponent<Props> = ({ file, wrapper: Wrapper = "div" }) => {
    const [contents, setContents] = useState<string | null>(null);
    const fileName = useTranslatedValue(file, null);
    const loadedContents = useStateSelector(getFileContents, fileName);
    const loadError = useStateSelector(getFileError, fileName);
    const requested = useStateSelector(isFileRequested, fileName);
    const loadFile = useAction(requestFile);

    useEffect(() => {
        if (fileName == null) {
            logger.warn("Cannot load file");
            return;
        }

        if (loadedContents != null && loadedContents !== contents) {
            setContents(loadedContents as string);
        }

        if (!requested && file != null) {
            loadFile(fileName, "html");
        }

    });

    if (loadError) {
        return <Wrapper>{loadError}</Wrapper>;
    }

    if (contents == null && requested) {
        return <Spinner />;
    }

    return (
        <CSSTransition mountOnEnter={true} unmountOnExit={true} classNames={"fade"} timeout={400} in={contents != null}>
            <Wrapper dangerouslySetInnerHTML={{ __html: contents }} />
        </CSSTransition>
    );
};

export default LazyHtmlContent;
