
import { Action } from "redux";

export enum IntroActions {
    INTRO_ENTER = "INTRO_ENTER",
    INTRO_LEAVE = "INTRO_LEAVE"
}

export interface IntroEnterAction extends Action<IntroActions.INTRO_ENTER> { }
export interface IntroLeaveAction extends Action<IntroActions.INTRO_LEAVE> { }

export const enterIntro = (): IntroEnterAction => ({
    type: IntroActions.INTRO_ENTER
});

export const leaveIntro = (): IntroLeaveAction => ({
    type: IntroActions.INTRO_LEAVE
});


export type IntroActionTypes = IntroEnterAction | IntroLeaveAction;
