import { TweenMax } from "gsap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { sprintf } from "sprintf-js";
import { ImageHotspot } from "../../krpano/Hotspot";
import { Topic } from "../../reducers";
import { KrpanoHotspotElement } from "../../types/krpano";


interface Props {
    active: boolean;
    topic: Topic;

    fadeDuration: number;
    fadeDelay: number;
}

const spec: Array<[string, number, number]> = [["f", 0, 0], ["l", -90, 0], ["r", 90, 0], ["u", 0, -90], ["d", 0, 90], ["b", 180, 0]];

const TopicOverlay: React.FunctionComponent<Props> = ({ active, topic, fadeDuration, fadeDelay }) => {
    const [tween, setTween] = useState<TweenMax | null>(null);
    const hotspots = useRef<KrpanoHotspotElement[]>([]);

    const assignHotspot = useCallback((hotspot: KrpanoHotspotElement | null) => {
        if (hotspot != null) {
            hotspots.current.push(hotspot);
        }
    }, [hotspots]);

    useEffect(() => {
        if (tween) {
            tween.kill();
        }
        setTween(TweenMax.to(
            hotspots.current,
            (fadeDuration / 1000),
            { delay: active ? (fadeDelay / 1000) : 0, alpha: active ? 1 : 0.01 }
        ));
    }, [hotspots, active]);

    if (topic.panoramaOverlay == null) {
        return null;
    }

    return (
        <React.Fragment>
            {spec.map(([name, ath, atv]) =>
                <ImageHotspot
                    key={name}
                    assignHotspot={assignHotspot}
                    url={sprintf(topic.panoramaOverlay as string, name)}
                    distorted={true}
                    ath={ath}
                    atv={atv}
                    width={1000}
                    height={1000}
                    enabled={false}
                    alpha={0.01}
                />)}
        </React.Fragment>
    );
};

export default React.memo(TopicOverlay);

