import React, { PureComponent } from "react";
import styles from "./Progress.module.css";

export interface ProgressProps {
    value: number;
    message?: string;
}

class Progress extends PureComponent<ProgressProps> {

    public render(): React.ReactNode {
        const { value, message } = this.props;
        return (
            <div className={styles.progress}>
                {message != null ?
                    <div className={styles["progress-message"]}>{message}</div>
                    : null}
                <div className={styles["progress-bar"]}>
                    <div className={styles["progress-bar-inner"]} style={{ width: value + "%" }} />
                </div>
                <div className={styles["progress-text"]}>{value.toFixed(2)}%</div>
            </div>
        );
    }
}

export default Progress;