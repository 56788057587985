import classNames from "classnames";
import round from "lodash/round";
import React, { CSSProperties } from "react";
import { openChapter } from "../../actions/chapter";
import { useAction } from "../../hooks/useAction";
import { Chapter, ChapterType } from "../../reducers";
import { Coordinate } from "../../services/math";
import { buildPublicPath } from "../../services/path";
import Button from "../Button";
import TranslateableText from "../locale/TranslateableText";
import styles from "./ChapterMenuItem.module.css";

interface Props {
    chapter: Chapter;
    position: Coordinate;
}

const getIconForChapterType = (chapter: Chapter): string => {
    switch (chapter.type) {
        case ChapterType.GAME:
            return "content/icons/game.svg";
        case ChapterType.HTML:
            return "content/icons/info.svg";
        case ChapterType.VIDEO:
            return "content/icons/video.svg";
        case ChapterType.SLIDESHOW:
            return "content/icons/info.svg";
    }
};

const ChapterMenuItem: React.FunctionComponent<Props> = ({ chapter, position }) => {
    const activateChapter = useAction(openChapter);
    const handleTap = () => {
        /**
         * @see https://krpano.com/forum/wbb/index.php?page=Thread&threadID=15229
         * 1000px relates to a fov of 90 degree. 
         */
        activateChapter(chapter, {
            addHorizontal: position.x / 1000 * 90,
            addVertical: position.y / 1000 * 90
        });
    };


    const style = {
        top: round(position.y, 4) + "px",
        left: round(position.x, 4) + "px"
    };

    const classes: string = classNames(styles.item, {
        "has-text": chapter.buttonText != null
    });

    const icon = chapter.buttonIcon ? chapter.buttonIcon : getIconForChapterType(chapter);
    const buttonTextStyle: CSSProperties = {
        fontSize: (chapter.buttonTextSize || 1) * 100 + "%"
    };

    return (
        <div className={styles["item-wrapper"]} style={style}>
            <Button component="div" onTap={handleTap} className={classNames(classes)}>
                <div className={styles.icon}>
                    <img className={chapter.type} src={buildPublicPath(icon)} />
                </div>
                {chapter.buttonText
                    ? <span className={styles.text}><TranslateableText style={buttonTextStyle} data={chapter.buttonText} /></span>
                    : null}

                {chapter.buttonNote
                    ? <TranslateableText className={styles.note} data={chapter.buttonNote} />
                    : null}
            </Button>
        </div>
    );
};

export default React.memo(ChapterMenuItem);
