import { createSelector } from "reselect";
import { ApplicationState, Chapter, Topic } from "../reducers";
import { getTopic } from "./topic";



export const getChapter = (state: ApplicationState): Chapter | null => {
    return state.chapter;
};

/**
 * Retrieve the chapters of the active topic
 */
export const getChapters = createSelector(
    getTopic,
    (topic: Topic | null): Chapter[] => topic ? topic.chapters : []
);

