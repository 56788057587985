import React, { ComponentType } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { StoreContext } from "redux-react-hook";
import { store } from "../services/store";
import Loader from "./Loader";

interface Props {
    component?: ComponentType;
}

const Provider: React.FunctionComponent<Props> = ({ component: Component, children }) => {
    return (
        <StoreContext.Provider value={store}>
            <ReduxProvider store={store}>
                {Component ? <Component /> : children}
            </ReduxProvider>
        </StoreContext.Provider>
    );
};

export default Provider;
