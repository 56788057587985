import classNames from "classnames";
import React, { useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import { activateTopic } from "../../actions/topic";
import { useAction } from "../../hooks/useAction";
import { HtmlHotspot } from "../../krpano/Hotspot";
import { Topic, TopicType } from "../../reducers";
import { isFirefox } from "../../services/browser";
import { buildPublicPath, getBasename } from "../../services/path";
import Animation from "../Animation";
import Button from "../Button";
import TranslateableText from "../locale/TranslateableText";
import ChapterMenu from "./ChapterMenu";
import HotspotRings from "./HotspotRings";
import styles from "./TopicHotspot.module.css";

interface Props {
    topic: Topic;
    active: boolean;
    idle: boolean;
}

const TopicHotspot = ({ idle, topic, active }: Props) => {
    const activate = useAction(activateTopic);
    const handleClick = useCallback(() => activate(topic, true), [topic]);

    if (topic.type !== TopicType.HOTSPOT) {
        return null;
    }

    return (
        <HtmlHotspot
            ath={topic.sphericalCoordinates.horizontalAngle || 0}
            atv={topic.sphericalCoordinates.verticalAngle || 0}
            width={1}
            height={1}
            scale={0.25}
            zorder={100}
            capture={false}
            distorted={true}
        >
            <div className={styles.hotspot}>
                <Button component="div" className={classNames(styles.text, { transform3d: !isFirefox })} onTap={handleClick}>
                    <TranslateableText data={topic.name} />
                </Button>
                <CSSTransition timeout={600} classNames="fade" in={active} mountOnEnter={true} unmountOnExit={true}>
                    <div className={styles.menu}>
                        <Animation timeout={1000} selector={".button"} in={!idle}>
                            <ChapterMenu chapters={topic.chapters} />
                        </Animation>
                        <HotspotRings />
                        <div className={styles.icon} style={{ backgroundColor: topic.theme.iconBackgroundColor }}>
                            <img className={classNames(styles["icon-topic"], getBasename(topic.icon))} src={buildPublicPath(topic.icon)} />
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </HtmlHotspot>
    );
};

export default React.memo(TopicHotspot);
