import React from "react";
import { LanguageSelectionConfiguration } from "../../data/Configuration";
import { useLocale } from "../../hooks/useLocale";
import { useMappedStateSelectors } from "../../hooks/useMappedStateSelectors";
import { useTranslation } from "../../hooks/useTranslations";
import { Locale } from "../../reducers";
import { getLanguageSelectionConfiguration } from "../../selectors/config";
import { getLocales } from "../../selectors/locale";
import TranslateableText from "../locale/TranslateableText";
import LanguageButton from "./LanguageButton";
import styles from "./LanguageSelection.module.css";
import LanguageSlice from "./LanguageSlice";

interface State {
    configuration: LanguageSelectionConfiguration;
    locales: Locale[];
}

const LanguageSelection = () => {
    const { configuration, locales } = useMappedStateSelectors<State>({
        configuration: getLanguageSelectionConfiguration,
        locales: getLocales
    });
    const [activeLocale, changeLocale] = useLocale();
    const props: Props = {
        configuration,
        locales,
        activeLocale,
        changeLocale
    };

    if (configuration.variant === "stacked") {
        return <LanguageSelectionStacked {...props} />;
    }

    return <LanguageSelectionCircular {...props} />;
};

interface Props {
    configuration: LanguageSelectionConfiguration;
    locales: Locale[];
    activeLocale: Locale;
    changeLocale(locale: Locale): void;
}

const LanguageSelectionCircular: React.FunctionComponent<Props> = ({ configuration, locales, activeLocale, changeLocale }) => {
    const size = configuration.circleRadius * 2;
    const sliceAngle = 360 / locales.length;

    return (
        <svg viewBox={`0 0 ${size} ${size}`} className={styles["language-selection-circular"]} preserveAspectRatio="xMidYMid meet">
            {locales.map((locale: Locale, i: number) => (
                <LanguageSlice
                    key={i}
                    locale={locale}
                    activate={changeLocale}
                    active={activeLocale === locale}
                    angle={sliceAngle}
                    circleRadius={configuration.circleRadius}
                    slicePadding={configuration.slicePadding}
                    rotation={configuration.rotationOffset + sliceAngle * i}
                />
            ))}
        </svg>
    );
};

const LanguageSelectionStacked: React.FunctionComponent<Props> = ({ locales, activeLocale, changeLocale }) => {
    const label: string = useTranslation("language", "Language");
    return (
        <div className={styles["language-selection-stacked"]}>
            {locales.map((locale: Locale, i: number) => (
                <LanguageButton key={i} locale={locale} activate={changeLocale} active={activeLocale === locale} />
            ))}
            <div className={styles["language-selection-label"]}>
                {label}
            </div>
        </div>
    );
};

export default LanguageSelection;
