import { put, select, takeEvery } from "redux-saga/effects";
import { IdleActions } from "../actions/idle";
import { enterIntro } from "../actions/intro";
import { IntroConfiguration } from "../data/Configuration";
import { getIntroConfiguration } from "../selectors/config";


function* handleIdleLeave() {
    const introConfig: IntroConfiguration = yield select(getIntroConfiguration);
    if (!introConfig.enabled) {
        return;
    }
    yield put(enterIntro());
}

export default [
    takeEvery(IdleActions.IDLE_LEAVE, handleIdleLeave),
];