import classNames from "classnames";
import React from "react";
import { Locale } from "../../reducers";
import Button from "../Button";
import TranslateableText from "../locale/TranslateableText";
import styles from "./LanguageButton.module.css";

interface Props {
    locale: Locale;
    active: boolean;
    activate(locale: Locale): void;
}

const LanguageButton = ({ active, locale, activate }: Props) => {
    const handleClick = () => activate(locale);

    return (
        <Button className={classNames(styles["language-button"], { active })} onTap={handleClick}>
            <TranslateableText wrapper={"span"} data={locale.name} />
        </Button>
    );
};


export default LanguageButton;
