import { all } from "redux-saga/effects";
import idleSagas from "./idle";
import introSagas from "./intro";
import loadSagas from "./load";
import rotationSagas from "./rotation";

export default function* rootSaga() {
    yield all([
        ...introSagas,
        ...idleSagas,
        ...rotationSagas,
        ...loadSagas
    ]);
}