import { useCallback } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { updateLocale } from "../actions/intl";
import { Locale } from "../reducers";
import { getLocale } from "../selectors/locale";

export const useLocale = (): [Locale, (locale: Locale) => void] => {
    const selector = useCallback(getLocale, []);
    const activeLocale: Locale = useMappedState(selector);
    const dispatch = useDispatch();
    const setLocale = useCallback((locale: Locale) => dispatch(updateLocale(locale)), []);
    return [activeLocale, setLocale];
};