import uniqueId from "lodash/uniqueId";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { KrpanoHotspotElement, KrpanoHotspotOptions, KrpanoImageHotspotOptions } from "../types/krpano";
import { KrpanoContext } from "./Krpano";


type Props<T extends KrpanoHotspotOptions = KrpanoHotspotOptions> = Partial<T> & {
    onloaded?(hotspot: KrpanoHotspotElement): void;
    oninitialize?(hotspot: KrpanoHotspotElement): void;
    visibleRange?: [number, number];
    assignHotspot?(hotspot: KrpanoHotspotElement | null): void;
};

const Hotspot: React.FunctionComponent<Props> = ({ assignHotspot, ...props }) => {
    const id = useMemo(() => uniqueId("hotspot-"), []);
    const [hotspot, setHotspot] = useState<KrpanoHotspotElement | null>(null);
    const context = useContext(KrpanoContext);

    const cleanupHotspot = () => {
        if (context == null) {
            return;
        }
        context.removehotspot(id);
    };

    const assignRef = () => {
        if (assignHotspot == null) {
            return;
        }
        assignHotspot(hotspot);
    };

    const updateHotspot = () => {
        if (context == null) {
            return;
        }

        /* console.log(context);

        if (!loadComplete) {
            context.events.createItem(id, {
                onnewpano: () => setLoadComplete(false),
                onloadcomplete: () => setLoadComplete(true)
            });
            return;
        } */

        if (!hotspot) {
            const item = context.hotspot.getItem(id);
            setHotspot(item == null ? context.addhotspot(id) : item);
            return;
        }

        Object.keys(props).forEach((key) => {
            hotspot[key as keyof KrpanoHotspotOptions] = props[key as keyof KrpanoHotspotOptions];
        });

        hotspot.onloaded = () => {
            if (props.onloaded) {
                props.onloaded(hotspot);
            }
        };
    };

    useEffect(updateHotspot);
    useEffect(assignRef, [hotspot, assignHotspot]);
    useEffect(() => cleanupHotspot, [context]);
    return null;
};

export const ImageHotspot: React.FunctionComponent<Props<KrpanoImageHotspotOptions>> = React.memo((props) => {
    return <Hotspot {...props} />;
});

interface HtmlHotspotProps extends Partial<KrpanoImageHotspotOptions> {
    width: number;
    height: number;
    visibleRange?: [number, number];
}

export const HtmlHotspot: React.FunctionComponent<HtmlHotspotProps> = React.memo(({ children, visibleRange, ...props }) => {
    const [sprite, setSprite] = useState<HTMLDivElement | null>(null);

    const handleLoaded = (hotspot?: KrpanoHotspotElement) => {
        if (hotspot == null) {
            return;
        }
        if (!hotspot.sprite) {
            return;
        }
        hotspot.registercontentsize(props.width, props.height);
        setSprite(hotspot.sprite);
    };

    return (
        <React.Fragment>
            <ImageHotspot
                url={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="}
                distorted={true}
                keep={true}
                {...props}
                renderer={"css3d"}
                onloaded={handleLoaded}
            />
            {sprite != null ? ReactDOM.createPortal(children, sprite) : null}
        </React.Fragment>
    );
});
