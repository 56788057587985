import { useContext, useEffect, useState } from "react";
import { clearRotateTo } from "../../actions/rotation";
import { useAction } from "../../hooks/useAction";
import { useStateSelector } from "../../hooks/useStateSelector";
import { KrpanoContext } from "../../krpano/Krpano";
import { getTargetRotation } from "../../selectors/rotation";

const RotationHandler = () => {
    const krpano = useContext(KrpanoContext);
    const clearTargetRotation = useAction(clearRotateTo);
    const targetRotation = useStateSelector(getTargetRotation);
    const [isTransitioning, setTransitioning] = useState(false);

    const handleLookToDone = () => {
        clearTargetRotation();
        setTransitioning(false);
    };

    useEffect(() => {
        if (krpano != null && targetRotation != null && !isTransitioning) {
            if (targetRotation.instant) {
                krpano.actions.lookat(
                    targetRotation.horizontal,
                    targetRotation.vertical,
                    targetRotation.fov
                );
                clearTargetRotation();
            } else {

                krpano.set("looktodone", handleLookToDone);
                krpano.actions.oninterrupt("looktodone");

                krpano.actions.lookto(
                    targetRotation.horizontal,
                    targetRotation.vertical,
                    targetRotation.fov,
                    undefined,
                    true,
                    true,
                    "looktodone"
                );
                setTransitioning(true);
            }
        }
    }, [krpano, targetRotation, isTransitioning]);

    return null;
};

export default RotationHandler;
