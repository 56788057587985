import React, { useContext, useEffect } from "react";
import { KrpanoAutoRotateOptions } from "../types/krpano";
import { KrpanoContext } from "./Krpano";

const Autorotate = (props: Partial<KrpanoAutoRotateOptions>) => {
    const context = useContext(KrpanoContext);
    const updateAutorotate = () => {
        if (context == null) {
            return;
        }
        Object.keys(props).forEach((key: string) => {
            context.autorotate[key as keyof KrpanoAutoRotateOptions] = (props as KrpanoAutoRotateOptions)[key as keyof KrpanoAutoRotateOptions];
        });
    };

    useEffect(updateAutorotate);
    return null;
};

export default React.memo(Autorotate);
