import React, { useEffect, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { GameChapter } from "../../reducers";
import { getTranslatedValue } from "../../services/locale";
import styles from "./GameContent.module.css";

interface Props {
    chapter: GameChapter;
}

const GameContent: React.FunctionComponent<Props> = ({ chapter }) => {
    const [mounted, setMounted] = useState(false);
    const [locale] = useLocale();
    const file = getTranslatedValue(chapter.content.source, locale);

    useEffect(() => {
        const timeout = window.setTimeout(() => setMounted(true), 350);
        return () => {
            window.clearTimeout(timeout);
        };
    }, []);

    return (
        <div className={styles["game-content"]}>
            {file != null && mounted ? <iframe scrolling="no" src={file} /> : null}
        </div>

    );
};

export default GameContent;
