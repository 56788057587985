import classNames from "classnames";
import debounce from "lodash/debounce";
import React from "react";
import styles from "./Button.module.css";

export interface ButtonProps<T extends HTMLElement = HTMLElement> extends React.HTMLAttributes<T> {
    onTap?: (position: ButtonTapPosition, e: ButtonTapEvent<T>) => void;
    onLongTap?: (position: ButtonTapPosition, e: ButtonTapEvent<T>) => void;
    disabled?: boolean;
    component?: string;
    firstInteractionDelay?: number;
    tapDetectionDelay?: number;
    longTapDelay?: number;
    componentRef?: (el: T | null) => void;
    [key: string]: any;
    stopPropagation?: boolean;
    mouseOverClassName?: string;
    disabledClassName?: string;
    pointerDownClassName?: string;
}

interface State {
    interactable: boolean;
    mouseOver: boolean;
    pointerDown: boolean;
    touch: boolean;
    startPoint: number;
    needsClickFallback: boolean;
}

export type ButtonTapEvent<T extends HTMLElement = HTMLElement> = React.MouseEvent<T> | React.TouchEvent<T> | React.PointerEvent<T>;

export interface ButtonTapPosition {
    x: number;
    y: number;
}

class Button<T extends HTMLElement = HTMLElement> extends React.PureComponent<ButtonProps<T>, State> {

    /**
     * Long tap timer
     */
    private longTapTimer?: number;

    private enableInteractionTimeout?: number;

    public static defaultProps: Partial<ButtonProps> = {
        tapDetectionDelay: 200,
        firstInteractionDelay: 100,
        longTapDelay: 2000
    };

    constructor(props: ButtonProps) {
        super(props);

        this.state = {
            needsClickFallback: true,
            interactable: false,
            mouseOver: false,
            touch: false,
            pointerDown: false,
            startPoint: 0
        };

        const tapDelay: number = props.tapDetectionDelay || 200;

        this.onPointerDown = debounce(this.onPointerDown.bind(this), tapDelay, { leading: true, trailing: false });
        this.onPointerCancel = this.onPointerCancel.bind(this);
        this.onPointerUp = this.onPointerUp.bind(this);
        this.onPointerMove = this.onPointerMove.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    private isMouseEvent(e: any): e is React.MouseEvent {
        return e.clientX && e.clientY;
    }

    private isTouchEvent(e: any): e is React.TouchEvent {
        return e.touches != null && e.touches[0];
    }

    private isPointerEvent(e: any): e is React.PointerEvent {
        return e.pointerType != null;
    }

    private handlePointerDown = (e: ButtonTapEvent<T>): void => {
        // this.onPointerDown is debounced.
        // So we have to intercept here and persist the event
        // otherwise all props will be null.
        e.persist();

        this.onPointerDown(e);
    }

    private isTouch(e: ButtonTapEvent<T>): boolean {
        if (this.isTouchEvent(e)) {
            return true;
        }
        if (this.isPointerEvent(e)) {
            return e.pointerType === "touch";
        }
        return false;
    }

    private isInteractionEnabled(): boolean {
        if (this.props.disabled) {
            return false;
        }
        return this.state.interactable === true;
    }

    private onLongTap(e: ButtonTapEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        if (!this.state.pointerDown) {
            return;
        }
        if (!this.props.onLongTap) {
            return;
        }
        this.clearLongTapTimer();
        this.props.onLongTap(this.getPosition(e), e);
    }

    private onMouseOver(e: React.MouseEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        if (this.state.touch) {
            return;
        }
        this.setState({ mouseOver: true });
    }

    private onMouseLeave(e: React.MouseEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        this.setState({ mouseOver: false });
    }

    private onPointerDown(e: ButtonTapEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }

        if (this.props.stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (this.props.onLongTap) {
            e.persist();
            this.longTapTimer = window.setTimeout(() => this.onLongTap(e), this.props.longTapDelay || 2000);
        }

        this.setState({ needsClickFallback: false, pointerDown: true, touch: this.isTouch(e), startPoint: this.getPositionAsNumber(e) });
    }

    private getPosition(e: React.MouseEvent<T> | React.TouchEvent<T>): ButtonTapPosition {
        if (this.isMouseEvent(e)) {
            return { x: e.clientX, y: e.clientY };
        }
        if (this.isTouchEvent(e)) {
            return { x: e.touches[0].clientX, y: e.touches[0].clientY };
        }
        return { x: 0, y: 0 };
    }

    private getPositionAsNumber(e: React.MouseEvent<T> | React.TouchEvent<T>): number {
        const pos = this.getPosition(e);
        return pos.x + pos.y;
    }

    private onPointerCancel(): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        this.setState({ mouseOver: false, pointerDown: false, startPoint: 0 });
    }

    private onPointerMove(e: React.MouseEvent<T> | React.TouchEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        if (this.state.pointerDown !== true) {
            return;
        }

        const pos: number = this.getPositionAsNumber(e);
        if (Math.abs(this.state.startPoint - pos) > 15) {
            this.setState({ pointerDown: false, startPoint: 0 });
        }
    }

    private onPointerUp(e: ButtonTapEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        if (this.props.stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (this.state.pointerDown !== true) {
            return;
        }

        this.setState({ pointerDown: false, touch: this.isTouch(e), startPoint: 0 });
        if (this.props.onTap) {
            this.props.onTap(this.getPosition(e), e);
        }
    }

    private clearLongTapTimer(): void {
        if (this.longTapTimer) {
            window.clearTimeout(this.longTapTimer);
            delete this.longTapTimer;
        }
    }

    public onClick(e: React.MouseEvent<T>): void {
        if (!this.isInteractionEnabled()) {
            return;
        }
        if (this.props.stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (!this.state.needsClickFallback) {
            return;
        }

        this.setState({ pointerDown: false, touch: this.isTouch(e), startPoint: 0 });
        if (this.props.onTap) {
            this.props.onTap(this.getPosition(e), e);
        }
    }

    public componentWillUnmount(): void {
        if (this.enableInteractionTimeout) {
            window.clearTimeout(this.enableInteractionTimeout);
            delete this.enableInteractionTimeout;
        }

        this.clearLongTapTimer();
    }

    public componentDidUpdate(prevProps: ButtonProps<T>, prevState: State): void {
        if (this.props.disabled === true && prevProps.disabled !== true) {
            // Reset interaction state
            this.setState({ mouseOver: false, pointerDown: false, startPoint: 0 });
        }

        if (this.state.pointerDown === false && prevState.pointerDown === true) {
            this.clearLongTapTimer();
        }
    }

    public componentDidMount(): void {
        if (this.props.firstInteractionDelay != null
            && this.props.firstInteractionDelay > 0
        ) {
            this.enableInteractionTimeout = window.setTimeout(
                () => {
                    delete this.enableInteractionTimeout;
                    this.setState({ interactable: true });
                },
                this.props.firstInteractionDelay
            );
        } else {
            this.setState({ interactable: true });
        }
    }

    public render(): React.ReactNode {
        const {
            onTap,
            onLongTap,
            component,
            componentRef,
            disabled,
            firstInteractionDelay,
            tapDetectionDelay,
            longTapDelay,
            mouseOverClassName = "mouse-over",
            pointerDownClassName = "pointer-down",
            disabledClassName = "disabled",
            stopPropagation,
            ...rest
        } = this.props;

        const props: React.HTMLProps<T> = {
            ...rest,
            onPointerDown: this.handlePointerDown,
            onMouseDown: this.handlePointerDown,
            onTouchStart: this.handlePointerDown,
            onMouseOver: this.onMouseOver,
            onMouseLeave: this.onMouseLeave,
            onMouseOut: this.onPointerCancel,
            onPointerCancel: this.onPointerCancel,
            onTouchCancel: this.onPointerCancel,
            onPointerUp: this.onPointerUp,
            onTouchEnd: this.onPointerUp,
            onMouseUp: this.onPointerUp,
            onClick: this.onClick,
            ref: componentRef,
            className: classNames(this.props.className, "button", styles.button, {
                [pointerDownClassName]: this.props.disabled !== true && this.state.pointerDown,
                [mouseOverClassName]: this.props.disabled !== true && this.state.mouseOver,
                [disabledClassName]: (this.props.disabled === true)
            })
        };

        if (this.state.pointerDown) {
            props.onMouseMove = this.onPointerMove;
            props.onPointerMove = this.onPointerMove;
            props.onTouchMove = this.onPointerMove;
        }

        const Component: string = this.props.component || "button";
        return (
            <Component {...props}>
                {this.props.children}
            </Component>
        );
    }
}

export default Button;