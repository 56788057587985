import { Action } from "redux";
import { Locale, TranslateableString } from "../reducers";

export enum IntlActions {
    LOCALE_UPDATE = "LOCALE_UPDATE",
    LOCALES_UPDATE = "LOCALES_UPDATE",

    TRANSLATION_UPDATE = "TRANSLATION_UPDATE"
}

export interface UpdateLocaleAction extends Action<IntlActions.LOCALE_UPDATE> {
    locale: Locale;
}

export interface UpdateLocalesAction extends Action<IntlActions.LOCALES_UPDATE> {
    locales: Locale[];
}

export interface UpdateTranslationsAction extends Action<IntlActions.TRANSLATION_UPDATE> {
    translations: Record<string, TranslateableString>;
}

export const updateLocale = (locale: Locale): UpdateLocaleAction => ({
    type: IntlActions.LOCALE_UPDATE,
    locale
});

export const updateLocales = (locales: Locale[]): UpdateLocalesAction => ({
    type: IntlActions.LOCALES_UPDATE,
    locales
});

export const updateTranslations = (translations: Record<string, TranslateableString>): UpdateTranslationsAction => ({
    type: IntlActions.TRANSLATION_UPDATE,
    translations
});

export type IntlActionTypes = UpdateLocaleAction | UpdateLocalesAction | UpdateTranslationsAction;