import {
    Configuration,
    HotspotConfiguration,
    IdleConfiguration,
    IndicatorConfiguration,
    IntroConfiguration,
    LanguageSelectionConfiguration,
    RoomConfiguration,
    StaticUIConfiguration
} from "../data/Configuration";
import {
    ApplicationState
} from "../reducers";

export const getConfiguration = (state: ApplicationState): Configuration => {
    return state.config;
};

export const getStaticUIConfiguration = (state: ApplicationState): StaticUIConfiguration => {
    return state.config.staticUI;
};

export const getIndicatorConfiguration = (state: ApplicationState): IndicatorConfiguration => {
    return state.config.indicator;
};

export const getHotspotConfiguration = (state: ApplicationState): HotspotConfiguration => {
    return state.config.hotspots;
};

export const getLanguageSelectionConfiguration = (state: ApplicationState): LanguageSelectionConfiguration => {
    return state.config.languageSelection;
};

export const getRoomConfiguration = (state: ApplicationState): RoomConfiguration => {
    return state.config.room;
};

export const getIdleConfiguration = (state: ApplicationState): IdleConfiguration => {
    return state.config.idle;
};

export const getIntroConfiguration = (state: ApplicationState): IntroConfiguration => {
    return state.config.intro;
};