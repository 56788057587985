import { put, select, takeEvery } from "redux-saga/effects";
import { ConfigurationActions } from "../actions/config";
import { enterIntro, leaveIntro } from "../actions/intro";
import { IntroConfiguration } from "../data/Configuration";
import { getIntroConfiguration } from "../selectors/config";


function* handleConfigurationUpdate() {
    const introConfig: IntroConfiguration = yield select(getIntroConfiguration);
    if (!introConfig.enabled) {
        yield put(leaveIntro());
        return;
    }
    if (!introConfig.playOnStart) {
        yield put(leaveIntro());
    } else {
        yield put(enterIntro());
    }
}

export default [
    takeEvery(ConfigurationActions.CONFIGURATION_UPDATE, handleConfigurationUpdate),
];